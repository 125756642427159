:local {
  /* Theme-specific vars */
  [data-theme="default"] {
    --chk-bg-color: #ffffff;
  }

  [data-theme="dark"] {
    --chk-bg-color: #000000;
  }

  .checkbox {
    background-color: var(--chk-bg-color);
    border: 1px solid #49505a;
    border-radius: 2px;
    box-sizing: border-box;
    height: 16px;
    position: relative;
    width: 16px;
  }

  .checkmark--disabled {
    opacity: 0.8;
  }

  .checkbox.checkmark--disabled {
    border: 1px solid var(--neutral-700);
    background-color: var(--neutral-800);

    .checkbox__input {
      cursor: not-allowed;
    }
  }

  .checkbox__icon {
    align-items: center;
    color: var(--text-light-color);
    display: flex;
    left: calc(50%);
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
  }

  .checkmark--checked {
    border: 1px solid #2baef9 !important;
    background-color: #2baef9 !important;
  }

  .checkbox__input {
    cursor: pointer;
    width: 100% !important;
    height: 100% !important;
    left: 50%;
    margin: 0 !important;
    opacity: 0;
    padding: 0 !important;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
  }
}
