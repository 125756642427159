:local {
  [data-theme="default"] {
    --host-select-placeholder-text-color: #000;
  }

  [data-theme="dark"] {
    --host-select-placeholder-text-color: #fff;
  }

  .hostSelect__placeholder_text_color {
    color: var(--host-select-placeholder-text-color);
  }
}
