:local {
  [data-theme="default"] {
    --add-dm-button-blur: blur(8px);
    --add-dm-button-background: rgba(193, 203, 230, 0.54);
  }

  [data-theme="dark"] {
    --add-dm-button-blur: unset;
    --add-dm-button-background: rgba(249, 249, 255, 0.21);
  }

  @supports (backdrop-filter: var(--add-dm-button-blur)) {
    .add_dm {
      margin-bottom: 5px;
      border-radius: 5px;
      background-color: var(--add-dm-button-background);
      backdrop-filter: var(--add-dm-button-blur);
    }
  }

  @supports not (backdrop-filter: var(--add-dm-button-blur)) {
    .add_dm {
      margin-bottom: 5px;
      border-radius: 5px;
      background-color: var(--add-dm-button-background);
    }
  }

  .add_dm__action {
    border: none;
    padding: 5px 0;
    background: transparent;
  }

  .add_dm__icon {
    color: white;
    margin: 0 5px;
  }

  .add_dm_guest_icon {
    margin: 0 5px;
  }

  .add_dm_guest_icon--active {
    border: 1px solid #30aff4;
    box-shadow: 0 0 32px #30aff4;

    > span {
      color: #30aff4;
    }
  }
}
