:local {
  [data-theme="default"] {
    --controls-background-color: #f8f9fb;
    --controls-border: 0.5px solid #a8b1bd;
  }

  [data-theme="dark"] {
    --controls-background-color: rgba(33, 36, 65, 0.88);
    --controls-border: unset;
  }

  .controls {
    background-color: var(--controls-background-color);
    border: var(--controls-border);
    padding: 15px;
  }

  .controls_body_ain__text {
    padding-right: 5px;
  }
}
