:local {
  [data-theme="default"] {
    --messenger-background: #edf1f9;
  }

  [data-theme="dark"] {
    --messenger-background: var(--primary-purple);
  }

  .messenger {
    position: relative;
    margin-right: 5px;
  }

  .messenger__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: var(--messenger-background);
    background-size: cover;
  }
}
