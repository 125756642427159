:local {
  @import "../../style/helpers.scss";

  [data-theme="default"] {
    --legacy-primary-background-color: var(--primary-color);
    --legacy-primary-active-background-color: var(--primary-color-lighter);
    --legacy-primary-disabled-background-color: var(--primary-color-darker);
    --legacy-primary-font-color: hsl(0, 0%, 99%);
    --legacy-primary-disabled-font-color: hsl(0, 0%, 80%);

    --legacy-secondary-background-color: hsl(0, 0%, 92%);
    --legacy-secondary-background-color-active: hsl(0, 0%, 94%);
    --legacy-secondary-background-color-disabled: hsl(0, 0%, 95%);
    --legacy-secondary-font-color: hsl(0, 0%, 50%);
    --legacy-secondary-disabled-font-color: hsl(0, 0%, 80%);

    --btn-border-primary-color: none;
    --btn-shadow-bg-primary: var(--primary-color-lighter);
    --link-bg-color: #ffffff;
    --secondary-background-color: #ffffff;
    --secondary-border-color: #e4e8ee;
    --secondary-font-color: #49505a;
  }

  [data-theme="dark"] {
    --legacy-primary-background-color: var(--primary-color);
    --legacy-primary-active-background-color: var(--primary-color-lighter);
    --legacy-primary-disabled-background-color: var(--primary-color-darker);
    --legacy-primary-font-color: hsl(0, 0%, 90%);
    --legacy-primary-disabled-font-color: hsl(0, 0%, 80%);

    --legacy-secondary-background-color: hsl(0, 0%, 9%);
    --legacy-secondary-background-color-active: none;
    --legacy-secondary-background-color-disabled: hsl(0, 0%, 25%);
    --legacy-secondary-font-color: hsl(0, 0%, 50%);
    --legacy-secondary-disabled-font-color: hsl(0, 0%, 80%);

    --btn-border-primary-color: none;
    --btn-shadow-bg-primary: hsl(0, 0%, 12%);
    --link-bg-color: #000000;
    --secondary-background-color: #121417;
    --secondary-border-color: #6a7381;
    --secondary-font-color: #a8b1bd;
  }

  :root {
    --primary-background-color: #2baef9;
    --primary-font-color: #ffffff;
    --font-size-large: 16px;
    --font-size-medium: 14px;
    --font-size-small: 14px;
  }

  .button__container--disabled {
    opacity: 0.5;
  }

  .button__container--primary {
    background-color: var(--primary-background-color);
    border: none;
    color: var(--primary-font-color);
  }

  .button__container--secondary {
    background-color: var(--secondary-background-color);
    border: 1px solid var(--secondary-border-color);
    color: var(--secondary-font-color);
  }

  .button__container--legacyPrimary {
    background: var(--legacy-primary-background-color);
    color: var(--legacy-primary-font-color);

    > svg {
      fill: var(--legacy-primary-font-color);
    }

    &:hover,
    &:active {
      background: var(--legacy-primary-active-background-color);
      border-color: var(--legacy-primary-active-background-color);
    }

    &.isActive {
      text-decoration: underline;
      color: var(--btn-bg-tertiary-color-active);
    }

    &:disabled {
      color: var(--legacy-primary-disabled-font-color);
      background: var(--legacy-primary-disabled-background-color);

      > svg {
        fill: var(--legacy-primary-disabled-font-color);
      }
    }
  }

  .button__container--legacySecondary {
    background: var(--legacy-secondary-background-color);
    color: var(--legacy-secondary-font-color);

    > svg {
      fill: var(--legacy-secondary-font-color);
    }

    &:hover,
    &:active {
      background: var(--legacy-secondary-background-color-active);
      border-color: var(--legacy-secondary-background-color-active);
    }

    &:disabled {
      color: var(--legacy-secondary-background-color-disabled);
      border: 1px solid var(--legacy-secondary-background-color-disabled);

      > svg {
        fill: var(--legacy-secondary-font-color);
      }
    }
  }

  .button__container--large {
    font-size: var(--font-size-large);
    padding: 0.375em 1em;

    .button__containerIcon {
      height: 1em;
      width: 1em;
    }

    .button__text {
      height: 1.15em;
    }
  }

  .button__text {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 1.15em;
  }

  .button__container--medium {
    font-size: var(--font-size-medium);
    padding: 0.3575em 1.15em;

    .button__containerIcon {
      height: 1.15em;
      width: 1.15em;
    }

    .button__text {
      height: 1.15em;
    }
  }

  .button__container--small {
    font-size: var(--font-size-small);
    padding: 0.3575em 0.575em;

    .button__containerIcon {
      height: 1.15em;
      width: 1.15em;
    }

    .button__text {
      height: 1.15em;
    }
  }

  .button__container {
    align-items: center;
    border-radius: 0.25em;
    cursor: pointer;
    display: flex;
    font-family: var(--font-family);
    font-weight: 600;
    line-height: 1.25em;
    justify-content: center;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .button__link {
    background-color: var(--link-bg-color);
    border: none;
    color: var(--link-color);
    cursor: pointer;
    font-family: var(--font-family);
    text-decoration: none;
  }

  .button__container--transparent {
    background-color: initial;
    border: none;
  }
}
