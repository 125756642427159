@import "../../components/theme/theme";
@import "../theme";

:local {
  [data-theme="default"] {
    --multi-guest-table-header-cell-background-color: #e6e6e6;
    --multi-guest-table-header-cell-border-color: #dcdcdc;
    --multi-guest-table-empty-view-background-color: hsl(0, 0%, 95%);
    --multi-guest-table-empty-view-icon-color: hsl(0, 0%, 80%);
    --multi-guest-table-header-text-color: #000;
  }

  [data-theme="dark"] {
    --multi-guest-table-header-cell-background-color: #191c3a;
    --multi-guest-table-header-cell-border-color: #464e6d;
    --multi-guest-table-empty-view-background-color: #0c0a25;
    --multi-guest-table-empty-view-icon-color: #252945;
    --multi-guest-table-header-text-color: #fff;
  }

  @mixin instanceMenuItemBorder {
    border-width: 2px 1px;
    border-style: solid;
    border-color: var(--multi-guest-table-header-cell-border-color);
  }

  .meetingTable__emptyViewContainer {
    flex: 1;
    background-color: var(--multi-guest-table-empty-view-background-color);
  }

  .meetingTable__emptyViewContainer_icon {
    color: var(--multi-guest-table-empty-view-icon-color);
  }

  .emptyView_button {
    margin-top: 1.5rem;
  }

  .meetingTable_header {
    @include instanceMenuItemBorder();
    align-items: center;
    background: var(--multi-guest-table-header-cell-background-color);
    color: var(--multi-guest-table-header-text-color);
    display: flex;
    font-size: 12px;
  }

  .meetingTable_headerButtonContainer {
    @include instanceMenuItemBorder();
    background: var(--multi-guest-table-header-cell-background-color);
    height: 100%;
    width: 100%;
  }

  .meetingTable_headerButton {
    align-items: center;
    color: var(--multi-guest-table-header-text-color);
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 29px;
  }

  .meetingTable__headersContainer {
    position: sticky;
    top: 40px;
    z-index: 2;
  }

  .meetingTable__cell {
    background-color: var(--multi-guest-table-header-cell-background-color);
    border-bottom: 1px solid var(--border-color);
  }

  .meetingTable_headers {
    min-height: 40px;
    grid-auto-rows: minmax(26px, 100%);
    grid-template-columns: minmax(72px, 5.5%) // Toggle
      minmax(72px, 5.5%) // Active
      minmax(80px, 6%) // Start Time
      minmax(177px, 13%) // Meeting Types
      minmax(552px, 47%) // Attendees (Instance, Guest, Sequence)
      minmax(140px, 11%) // Last Activity
      minmax(104px, 7%) // Tags
      minmax(93px, 5%); // Cost
  }

  .meetingTable__grid {
    background-color: var(--multi-guest-table-header-cell-background-color);
    flex: 1;
    row-gap: 0;
    column-gap: 0;
    grid-auto-rows: minmax(26px, 100%);
    grid-template-columns: minmax(72px, 5.5%) // Toggle
      minmax(72px, 5.5%) // Active
      minmax(80px, 6%) // Start Time
      minmax(177px, 13%) // Meeting Types
      minmax(552px, 47%) // Attendees (Instance, Guest, Sequence)
      minmax(140px, 11%) // Last Activity
      minmax(104px, 7%) // Tags
      minmax(93px, 5%); // Cost
  }

  .meetingTable__attendeesHeader {
    display: flex;
    grid-row: 1;
  }

  .meetingTable__loadingBar {
    height: 0;
    position: sticky;
    top: 120px;
    z-index: 5;
  }

  .meetingTable__paginationContainer {
    background-color: var(--multi-guest-table-header-cell-background-color);
    border-top: 2px solid var(--multi-guest-table-header-cell-border-color);
    display: flex;
    align-items: center;
    padding-left: 24px;
    height: 40px;
  }

  .meetingTable__pagination {
    bottom: -1px;
    position: sticky;
  }

  .meetingType_dropdown__placeholder_icon {
    color: var(--multi-guest-table-header-text-color);
  }

  .meetingType_dropdown__placeholder_text {
    color: var(--multi-guest-table-header-text-color);
  }

  .meetingType_hidden_input {
    background-color: "transparent";
    border-width: 0;
    color: "transparent";
    max-height: 0;
    max-width: 0;
    position: fixed;
  }
}
