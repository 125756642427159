:local {
  [data-theme="default"] {
    --tag-bg-color: hsl(0, 0%, 90%);
    --tag-bg-color-light: hsl(0, 0%, 95%);
    --tag-text-color-light: hsl(0, 0%, 40%);
    --tag-text-color: hsl(0, 0%, 40%);
    --tag-warining-color: rgb(236, 190, 6);

    /** tag status */
    --tag-accepted-bg: #02ba86;
    --tag-initializing-bg: linear-gradient(
      145deg,
      #e9e8eb 25%,
      #dcdbde 25%,
      #dcdbde 50%,
      #e9e8eb 50%,
      #e9e8eb 75%,
      #dcdbde 75%,
      #dcdbde 100%
    );
  }

  [data-theme="dark"] {
    --tag-bg-color: hsl(0, 0%, 10%);
    --tag-bg-color-light: hsl(0, 0%, 20%);
    --tag-text-color-light: hsl(0, 0%, 90%);
    --tag-text-color: hsl(0, 0%, 90%);
    --tag-warining-color: rgb(236, 190, 6);

    /** tag status */
    --tag-accepted-bg: #02ba86;
    --tag-initializing-bg: linear-gradient(
      145deg,
      #0d0d0d 25%,
      #1f1f1f 25%,
      #1f1f1f 50%,
      #0d0d0d 50%,
      #0d0d0d 75%,
      #1f1f1f 75%,
      #1f1f1f 100%
    );
  }

  .tag[data-size="default"] {
    --width: 60px;
    --height: 30px;
    --c-width: 26px;
    --c-height: 26px;
    --c-active-width: 40px;
  }

  .tag[data-size="xsmall"] {
    --width: 30px;
    --height: 16px;
    --c-width: 12px;
    --c-height: 12px;
    --c-active-width: 15px;
  }

  .tag--clickable {
    cursor: pointer;
  }

  .tag {
    border-radius: var(--border-radius);
    background: var(--tag-bg-color);
    padding: 7px 15px;
    color: var(--tag-text-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;

    > span {
      margin-right: 15px;
    }

    &.info {
      background: var(--primary-color);
      color: var(--whisper-color);
    }

    &.error {
      background: var(--fire-color);
      color: var(--whisper-color);
    }

    &.warning {
      background: var(--tag-warining-color);
      color: var(--whisper-color);
    }

    &.light {
      background-color: var(--tag-bg-color-light);
      color: var(--tag-text-color-light);
    }

    &.go,
    &.accepted {
      background-color: var(--tag-accepted-bg);
      color: #fff;
    }

    &.initializing {
      background-image: var(--tag-initializing-bg);
      background-size: 34.87px 24.42px;
    }
  }
}
