@import "../../../../style/mixins";
@import "../cards";

:local {
  [data-theme="default"] {
    --instance-provisioning-card-by-color: inheirt;
  }

  [data-theme="dark"] {
    --instance-provisioning-card-by-color: #0c0a25;
  }

  .instanceProvisioning {
    flex: 1 1 25%;
    padding: 5px;
  }

  .instanceProvisioning__card {
    background-color: var(--instance-provisioning-card-by-color);
  }

  .instanceProvisioning__card_content {
    min-height: 140px;
    padding: 0 15%;
  }

  .instanceProvisioning__text {
    flex: 3 3 75%;
    padding-bottom: $text-above-value-padding;
  }

  .instanceProvisioning__text_total {
    flex: 3 3 75%;
    padding: 0 5% $text-above-value-padding 0;
  }

  .instanceProvisioning__value {
    flex: 1 1 25%;
    color: $text-color-instance-stages;
    padding-bottom: $text-above-value-padding;
    justify-content: flex-start;

    &:hover {
      @include hover-underline;
    }
  }
}
