@import "../../components/theme/theme";
@import "../theme";

:local {
  [data-theme="default"] {
    --multi-guest-table-toggle-all-meetings-background-color: hsl(0, 0%, 95%);
    --multi-guest-table-toggle-all-meetings-border-color: 1px solid #49505a;
    --multi-guest-table-cells-text-color: #000;
    --instances-currency-color: #02ba86;
    --touch-bg: hsl(0, 0%, 81%);
  }

  [data-theme="dark"] {
    --multi-guest-table-toggle-all-meetings-background-color: #464e6d;
    --multi-guest-table-toggle-all-meetings-border-color: 1px solid #191c3a;
    --multi-guest-table-cells-text-color: #fff;
    --instances-currency-color: #02ba86;
    --touch-bg: hsl(0, 0%, 20%);
  }

  .currency {
    color: var(--instances-currency-color);
    font-weight: 600;
    min-width: 4.5em;
  }

  .multiCell {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    align-items: center;

    .subItem {
      display: flex;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .remove {
    margin-right: 5px;

    > .removeBtn {
      height: auto;
      padding: 0;
    }
  }

  .touches {
    display: flex;

    .touch {
      background-color: white;
      margin-right: 5px;
      width: 12px;
      height: 12px;
      border-radius: 0 6px 6px 6px;
      transform: rotate(45deg);

      &:last-child {
        margin-right: 0;
      }

      &.paintBlue {
        background-color: var(--multi-guest-table-cells-text-color-color);
      }

      &.paintGray {
        background-color: var(--touch-bg);
      }
    }
  }

  .lastActivityCell {
    min-width: 3em;
  }

  .meetingTags__cellButton {
    border: none;
    background: none;
    color: var(--multi-guest-table-cells-text-color);
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .filter {
    display: flex;
    position: absolute;
    top: -10px;
    right: 0;
    opacity: 1;
    margin-right: 5px;
  }

  .meetingTags__cell {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .meetingTags__row {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .meetingType__filterPlaceHolder {
    align-items: center;
    color: var(--multi-guest-table-cells-text-color);
    display: flex;
    justify-content: center;
  }

  .meetingType__filterPlaceHolderText {
    margin-right: 13px;
    color: var(--multi-guest-table-cells-text-color);
  }

  .meetingType__filterPlaceHolderIcon {
    color: var(--multi-guest-table-cells-text-color);
  }

  .toggleAllMeetings__checkbox {
    background-color: var(
      --multi-guest-table-toggle-all-meetings-background-color
    );
    border: var(--multi-guest-table-toggle-all-meetings-border-color);
    width: 25px;
    height: 25px;
  }

  .tag__Button {
    background-color: transparent;
    border: 1px solid var(--tags-border-color);
  }
}
