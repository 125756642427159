:local {
  [data-theme="default"] {
    --card-custom-bg-color: hsl(0, 0%, 99%);
    --card-custom-box-shadow-color: rgba(136, 136, 136, 0.11);
  }

  [data-theme="dark"] {
    --card-custom-bg-color: hsl(0, 0%, 7%);
    --card-custom-box-shadow-color: rgba(2, 2, 2, 0.61);
  }

  .card_wrapper {
    padding: 0px;
    padding-left: 10px;
    border-radius: 5px;
    margin-top: 25px;
    width: 50%;
    min-width: 900px;
    margin-left: auto;
    margin-right: auto;
    background: var(--card-custom-bg-color);
    box-shadow: 0 5px 10px var(--card-custom-box-shadow-color);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

    .card_wrapper__filter_by {
      float: left;
      min-width: 145px;
      max-width: 145px;
      padding-right: 10px;
      text-align: center;
      margin-right: -2px;
      margin-top: 20px;
      font-weight: bold;
    }

    .card_wrapper__condition_cards_wrapper {
      padding-top: 11px;
      margin-left: 145px;
    }
  }

  .channel_actions {
    margin-top: 1rem;
    width: 50%;
    min-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
  }

  .sampleButtons {
    margin-right: auto;
    display: flex;
  }

  .clearSampleButton {
    margin-left: 5px;
  }

  .lastProcessTime {
    position: sticky;
    bottom: 50px;
    margin-right: 10px;
    float: right;
    margin-top: 40px;
    color: white;
  }

  .errorRetrievingSampleData {
    text-align: center;
    color: var(--chilean-fire-color);
    margin-bottom: 10px;
  }

  .loader_wrapper {
    display: flex;
    flex-direction: column;
  }

  .loader_content {
    margin-left: auto;
    margin-right: auto;
  }
}
