@import "../../style/helpers.scss";

:local {
  [data-theme="default"] {
    --table-header-background-color: #fafafa;
    --table-cell-background-color: #ffffff;
    --table-cell-border-color: #e4e8ee;
  }

  [data-theme="dark"] {
    --table-header-background-color: #121417;
    --table-cell-background-color: #000000;
    --table-cell-border-color: #1f2329;
  }

  :root {
    --font-size: 14px;
  }

  .table--grid {
    display: grid;
  }

  .table--basic {
    border-collapse: collapse;
    width: 100%;

    .table_header {
      background-color: var(--table-header-background-color);
      border: none;
      font-size: var(--font-size);
      text-align: left;
    }

    .table_cell {
      background-color: var(--table-cell-background-color);
      border-top: 1px solid var(--table-cell-border-color);
      font-size: var(--font-size);
    }

    @mixin textCell {
      padding: 16px;
    }

    .table_headerTextCell {
      @include textCell;
      background-color: var(--table-header-background-color);
      border: none;
      font-size: var(--font-size);
      text-align: left;
    }

    .table_textCell {
      @include textCell;
      background-color: var(--table-cell-background-color);
      border-top: 1px solid var(--table-cell-border-color);
    }
  }
}
