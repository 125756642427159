:local {
  [data-theme="default"] {
    --contact-header-info-color: hsl(0, 0%, 80%);
    --contact-header-icon-fill-color: hsl(0, 0%, 35%);
    --channel-add-integration-bg: hsl(0, 0%, 90%);

    --card-custom-bg-color: hsl(0, 0%, 99%);
    --card-custom-bg-color-darker: hsl(0, 0%, 97%);
    --card-custom-text-color: hsl(0, 0%, 19%);
    --card-custom-icon-color: hsl(0, 0%, 50%);
    --card-custom-box-shadow-color: rgba(136, 136, 136, 0.11);
    --card-custom-box-shadow-active-from: hsla(202, 94%, 17%, 0.331);
    --card-custom-box-shadow-active-to: hsla(202, 94%, 17%, 0.001);
    --card-custom-error: hsla(357, 93%, 46%, 0.5);
    --card-custom-valid: hsla(83, 93%, 46%, 0.5);
  }

  [data-theme="dark"] {
    --contact-header-info-color: hsl(0, 0%, 30%);
    --contact-header-icon-fill-color: hsl(0, 0%, 90%);
    --channel-add-integration-bg: hsl(0, 0%, 15%);

    --card-custom-text-color: hsl(0, 0%, 90%);
    --card-custom-bg-color: hsl(0, 0%, 10%);
    --card-custom-bg-color-darker: hsl(0, 0%, 7%);
    --card-custom-icon-color: hsl(0, 0%, 55%);
    --card-custom-box-shadow-color: rgba(2, 2, 2, 0.61);
    --card-custom-box-shadow-active-from: hsl(0, 0%, 0%);
    --card-custom-box-shadow-active-to: hsl(0, 0%, 0%);
    --card-custom-error: hsla(357, 93%, 46%, 0.5);
    --card-custom-valid: hsla(83, 93%, 46%, 0.5);
  }

  .channels__link,
  .channels__link:visited {
    color: var(--primary-color);
  }

  .title {
    margin-top: 1rem;
    text-transform: capitalize;
    font-weight: 600;
  }

  .meeting_status {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;

    .meeting_status__text {
      margin-top: auto;
      margin-bottom: auto;
      min-width: 145px;
      max-width: 145px;
      text-align: right;
      padding-right: 10px;
    }

    .meeting_status__dropdowns {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 10px;

      .meeting_status__dropdowns_each {
        width: 100%;
      }
    }
  }

  .meetingInstance__card {
    padding: 0px;
    padding-left: 10px;
    border-radius: 5px;
    background: var(--card-custom-bg-color);
    margin-top: 25px;
    width: 50%;
    min-width: 900px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 5px 10px var(--card-custom-box-shadow-color);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

    .meetingInstance__card_header {
      padding-top: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .meetingInstance__card_header_icon {
        fill: var(--contact-header-icon-fill-color);
        margin-right: 20px;
        min-width: 3rem;
        height: 3rem;
      }

      .meetingInstance__card_header_text {
        font-size: 16px;
        margin-right: 20px;
        text-transform: capitalize;
        font-weight: 600;
      }

      .meetingInstance__card_header_input_wrapper {
        width: 100%;

        .meetingInstance__card_header_input {
          width: 100%;
          font-size: 24px;
          height: 50px;
        }
      }

      .meetingInstance__card_header_actions {
        padding-left: 5px;
        flex-basis: 15%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .meetingInstance__card_header_actions_text {
          color: var(--contact-header-info-color);
          font-size: 16px;
          font-weight: 400;
          margin-right: 10px;
          text-transform: none;
        }
      }

      .meetingInstance__card_close {
        margin-bottom: auto;
        width: 2rem !important;
        height: 2rem !important;
        fill: var(--close-modal-color);
        transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s;
        cursor: pointer;

        &:hover {
          transform: scale(1.25);
        }
      }
    }

    .meetingInstance__card_body_wrapper {
      display: flex;
      width: 100%;
      padding-right: 40px;
      padding-top: 1rem;
      padding-bottom: 0.5rem;

      .meetingInstance__card_body_wrapper__text_wrapper {
        min-width: 145px;
        max-width: 145px;
        padding-right: 10px;
        text-align: right;
        font-weight: 600;
      }

      .meetingInstance__card_body_wrapper__text_wrapper__INTERCEPT {
        min-width: 165px;
        max-width: 165px;
        padding-right: 10px;
        text-align: right;
        margin-right: -2px;
        margin-top: 7px;
        font-weight: 600;
      }

      .meetingInstance__card_body_meeting_instance {
        display: flex;
        align-items: center;
        width: 100%;
        margin-right: 10px;
      }

      .meetingInstance__card_body_meeting_instance__INTERCEPT {
        width: 100%;
      }

      .meetingInstance__card_body_meeting_instance__dropdown {
        width: 100%;
      }

      .meetingInstance__card_body_status {
        display: flex;
        align-items: center;
        width: 100%;

        .meetingInstance__card_body_status__dropdown {
          width: 100%;
        }
      }
    }

    .meetingInstance__card_meeting_statuses {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-right: 40px;

      .meetingInstance__card_meeting_statuses_empty_div {
        width: 100%;
      }
    }
  }

  .integrations__card {
    margin-top: 25px;
    display: flex;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 40px;

    .integrations__card_integration__text {
      min-width: 145px;
      max-width: 145px;
      padding-right: 10px;
      text-align: center;
      margin-right: -2px;
      margin-top: auto;
      margin-bottom: auto;
      font-weight: bold;
    }

    .integrations__card__dropdown_wrapper {
      margin-right: 10px;
      width: 100%;

      .integrations__card__locked_dropdown {
        width: 100%;
      }

      .integrations__card_integration__dropdown {
        width: 100%;
      }
    }

    .integrations__card_integration_title {
      text-transform: capitalize;
      width: 100%;
      margin-right: 10px;
    }

    .integrations__card_field_object {
      width: 100%;

      .loadingSFDCText {
        height: 45px;
        margin-top: 15px;
      }

      .integrations__card_field_object__dropdown {
        width: 100%;
      }
    }
  }

  .conditions__card {
    min-width: 755px;
    padding-right: 10px;

    .conditions__card_header {
      position: absolute;
      top: 5px;
      right: 0;
    }

    .conditions__card_condition_wrapper {
      display: flex;
      flex-direction: column;
      padding: 4px 0px;

      .conditions__card_condition_dropdowns_wrapper {
        display: flex;
        flex-direction: row;
        place-items: center;

        .conditions__card_condition_dropdowns_wrapper__and_or {
          min-width: 40px;
        }

        .conditions__card_condition_field {
          .conditions__card_condition_field_dropdown {
            margin-right: 10px;
            width: 200px;
          }

          .conditions__card_condition_field_input {
            margin-right: 10px;
          }
        }

        .conditions__card_condition_condition {
          .conditions__card_condition_condition_dropdown {
            width: 200px;
            margin-right: 10px;
          }
        }

        .conditions__card_condition_value {
          width: 100%;

          .conditions__card_condition_value_dropdown {
            border-radius: 0px 4px 4px 0px;
            width: 100%;
          }

          .conditions__card_condition_value_input {
            width: 100%;
          }
        }

        .conditions__card_condition_locked_dropdown_field {
          width: 100%;
          margin-right: 10px;
        }

        .conditions__card_condition_locked_dropdown_condition {
          width: 100%;
          margin-right: 10px;
        }

        .conditions__card_condition_locked_dropdown_value {
          width: 100%;
        }

        .conditions__card_condition_dropdowns_wrapper__bolded_words {
          font-weight: bold;
        }

        .conditionText__hover {
          position: absolute;
          margin-left: 700px;
          text-align: left;
          max-width: 400px;
          word-wrap: break-word;
          background-color: var(--card-custom-bg-color-darker);
          border-radius: 5px;
          padding: 5px;
        }
      }

      .conditions__card_condition_wrapper__add_buttons {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .invalid_dropdown {
    border: 1px solid var(--chilean-color) !important;
  }

  .sampleImportData {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 25%;
    min-width: 900px;
    padding-bottom: 20px;
    color: white;
  }

  .sampleImport__topBar {
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
  }

  .sampleImport__topBar__item {
    width: 25%;
  }

  .sampleImport__contacts {
    overflow: scroll;
    height: 100%;
  }

  .sampleImport__contacts__row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .sampleImport__contacts__row__item {
    width: 25%;
  }

  .totalCount {
    position: sticky;
    bottom: 50px;
    margin-left: 10px;
    min-width: 500px;
    max-width: 50%;
    float: left;
    margin-top: 20px;
    color: white;
  }

  .fieldMappingCard {
    margin-top: 15px;
    margin-bottom: 35px;
    display: flex;
    width: 100%;
    padding-right: 40px;
  }

  .fieldMappingCard__mappings {
    width: 100%;
    display: flex;
    margin-left: 150px;
  }

  .fieldMappingCard__mappings__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 135px;
  }

  .fieldMappingCard__mappings__content__boldedWords {
    font-weight: bold;
  }

  .outreach_condition_text {
    margin-left: 4px;
    margin-right: 4px;
    white-space: nowrap;
  }

  .checkbox_wrapper {
    display: flex;
    min-width: 120px;
  }

  .check_associated_objects_checkbox {
    margin: auto;
    margin-left: 15px;
    min-width: 20px;
    min-height: 20px;
  }

  .check_text {
    margin: auto;
    font-size: smaller;
    margin-left: 7px;
    text-align: left;
  }

  .hover_box {
    color: white;
    margin-left: 170px;
    position: absolute;
  }
}
