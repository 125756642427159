$font-size-x-small: 0.6em;
$font-size-small: 0.8em;
$font-size-medium: 1.2em;
$font-size-large: 1.6em;
$font-size-x-large: 2.2em;

$line-height-x-small: 10px;
$line-height-small: 12px;
$line-height-medium: 16px;
$line-height-large: 20px;
$line-height-x-large: 25px;
$line-height-xx-large: 30px;

@mixin text-size($size, $color: var(--primary-text-color), $weight: normal) {
  @if $size !=
    x-small and
    $size !=
    small and
    $size !=
    medium and
    $size !=
    large and
    $size !=
    x-large
  {
    @error "Property #{#size} must be either x-small, small, medium, large or x-large.";
  }

  font-family: var(--font-family);
  font-style: normal;
  font-weight: $weight;
  color: $color;

  @if $size == x-small {
    font-size: $font-size-x-small;

    @if $weight == bold {
      line-height: $line-height-small;
    } @else {
      line-height: $line-height-x-small;
    }
  }

  @if $size == small {
    font-size: $font-size-small;

    @if $weight == bold {
      line-height: $line-height-medium;
    } @else {
      line-height: $line-height-small;
    }
  }

  @if $size == medium {
    font-size: $font-size-medium;

    @if $weight == bold {
      line-height: $line-height-large;
    } @else {
      line-height: $line-height-medium;
    }
  }

  @if $size == large {
    font-size: $font-size-large;

    @if $weight == bold {
      line-height: $line-height-x-large;
    } @else {
      line-height: $line-height-large;
    }
  }

  @if $size == x-large {
    font-size: $font-size-x-large;

    @if $weight == bold {
      line-height: $line-height-xx-large;
    } @else {
      line-height: $line-height-x-large;
    }
  }
}
