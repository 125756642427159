:local {
  [data-theme="default"] {
    --tab-content-color: hsl(0, 0%, 40%);
    --tab-bg-color: hsl(0, 0%, 95%);
    --tab-bg-color-active: hsl(0, 0%, 97%);
    --tab-border-color: hsl(0, 0%, 90%);
    --tab-border-color-active: hsl(202, 94%, 57%);

    --tab-border-tab-bg: hsl(0, 0%, 90%);
    --tab-border-tab-bg-active: hsl(0, 0%, 95%);
  }

  [data-theme="dark"] {
    --tab-content-color: hsl(0, 0%, 90%);
    --tab-bg-color: hsl(0, 0%, 10%);
    --tab-bg-color-active: hsl(0, 0%, 10%);
    --tab-border-color: hsl(0, 0%, 9%);
    --tab-border-color-active: hsl(202, 94%, 57%);

    --tab-border-tab-bg: hsl(0, 0%, 22%);
    --tab-border-tab-bg-active: hsl(0, 0%, 10%);
  }

  .tabs {
    display: flex;
    width: 100%;

    .tab {
      padding: 0;
      border-radius: 0;
      color: var(--tab-content-color);
      height: auto;

      > h3 {
        align-items: center;
        background-color: var(--tab-border-tab-bg);
        display: flex;
        font-family: var(--font-family);
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
        padding: 10px 30px;
        text-transform: capitalize;

        .icon {
          margin: 0 10px 0 0;
          fill: var(--tab-content-color);
        }
      }

      &:last-child {
        border-right: none;
      }

      &.active {
        > h3 {
          margin-top: -5px;
          padding: 15px 30px;
          font-weight: 600;
          background-color: var(--tab-border-tab-bg-active);

          .icon {
            fill: var(--tab-border-color-active);
            width: 2rem !important;
            height: 2rem !important;
          }
        }
      }

      &:hover {
        > h3 {
          .icon {
            fill: var(--tab-border-color-active);
          }
        }
      }
    }
  }
}
