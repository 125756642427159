@import "../../style/helpers.scss";
@import "../shadows/style.module.scss";

:local {
  [data-theme="default"] {
    --drawer-shadow-color: #000000;
    --drawer-bg-color: #ffffff;
    --content-font-color: #595959;
    --header-shadow-color: #eef1f6;
  }

  [data-theme="dark"] {
    --drawer-bg-color: #000000;
    --content-font-color: #ffffff;
    --header-shadow-color: #1f2329;
  }

  :root {
    --content-font-size: 14px;
    --footer-font-size: 16px;
    --header-font-size: 16px;
  }

  .drawer__container {
    background-color: var(--drawer-bg-color);
    box-shadow: var(--kl-shadow--xl);
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }

  .drawer__spacing {
    height: 1.725em;
  }

  .drawer__content {
    display: flex;
    flex-flow: column;
    flex: 1;
    font-size: var(--content-font-size);
    overflow: scroll;
    padding: 0 1.725em;
  }

  .drawer__footer {
    box-shadow: inset 0px 1px 0px var(--header-shadow-color);
    display: flex;
    flex: 0;
    font-size: var(--footer-font-size);
    height: 52px;
    padding: 10px 16px;
  }

  .drawer__header {
    box-shadow: inset 0px -1px 0px var(--header-shadow-color);
    flex: 0;
    height: 52px;
    padding: 16px;
  }
}
