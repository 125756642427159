@import "../../style/mixins";

:local {
  .header {
    &__small {
      flex: 1 1 25%;
    }

    &__large {
      flex: 2 2 50%;
    }
  }

  .row_title {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin: 0 0.25em;
  }
}
