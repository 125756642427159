:local {
  .spacing--tiny {
    flex-shrink: 0;
    height: var(--kl-spacing-tiny);
    width: var(--kl-spacing-tiny);
  }

  .spacing--x-small {
    flex-shrink: 0;
    height: var(--kl-spacing-x-small);
    width: var(--kl-spacing-x-small);
  }

  .spacing--small {
    flex-shrink: 0;
    height: var(--kl-spacing-small);
    width: var(--kl-spacing-small);
  }

  .spacing--med {
    flex-shrink: 0;
    height: var(--kl-spacing-med);
    width: var(--kl-spacing-med);
  }

  .spacing--large {
    flex-shrink: 0;
    height: var(--kl-spacing-large);
    width: var(--kl-spacing-large);
  }

  .spacing--x-large {
    flex-shrink: 0;
    height: var(--kl-spacing-x-large);
    width: var(--kl-spacing-x-large);
  }

  .spacing--xx-large {
    flex-shrink: 0;
    height: var(--kl-spacing-xx-large);
    width: var(--kl-spacing-xx-large);
  }

  .spacing--xxx-large {
    flex-shrink: 0;
    height: var(--kl-spacing-xxx-large);
    width: var(--kl-spacing-xxx-large);
  }

  .spacing--jumbo {
    flex-shrink: 0;
    height: var(--kl-spacing-jumbo);
    width: var(--kl-spacing-jumbo);
  }

  .spacing--super-jumbo {
    flex-shrink: 0;
    height: var(--kl-spacing-super-jumbo);
    width: var(--kl-spacing-super-jumbo);
  }

  .spacing--horizontal-only {
    height: 1px;
  }

  .spacing--vertical-only {
    width: 1px;
  }
}
