@import "../../components/theme/theme";
@import "../theme";

[data-theme="default"] {
  --multi-guest-menu-cell-background-color: #e6e6e6;
  --multi-guest-menu-cell-border-color: #dcdcdc;
  --multi-guest-menu-text-color: #000;
  --disabled-opacity: 0.6;
}

[data-theme="dark"] {
  --multi-guest-menu-cell-background-color: #191c3a;
  --multi-guest-menu-cell-border-color: #464e6d;
  --multi-guest-menu-text-color: #fff;
  --disabled-opacity: 0.1;
}

:local {
  @mixin instanceMenuTheme {
    background-color: var(--multi-guest-menu-cell-background-color);
    color: var(--multi-guest-menu-text-color);
  }

  @mixin instanceMenuItemBorder {
    border-width: 2px 1px 0;
    border-style: solid;
    border-color: var(--multi-guest-menu-cell-border-color);
  }

  .instanceMenu {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    height: 40px;
    position: sticky;
    top: 0px;
    z-index: 6;
  }

  .instanceMenu__actionButton {
    min-height: 100%;
    border: 1px solid #30aff4;
    border-radius: 1px;
    width: 50px;
  }

  .instanceMenu__buttonContainer {
    @include instanceMenuItemBorder();
    background-color: var(--multi-guest-menu-cell-background-color);
    display: flex;
  }

  .instanceMenu__button {
    @include instanceMenuTheme();
    border: 0;
    border-radius: 0;
    width: 50px;
  }

  .instanceMenu__button:disabled {
    opacity: var(--disabled-opacity);
  }

  .instanceMenu__selectedCount {
    @include instanceMenuItemBorder();
    @include instanceMenuTheme();
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    max-width: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .instanceMenu__space {
    @include instanceMenuItemBorder();
    background-color: var(--multi-guest-menu-cell-background-color);
    border-radius: 0;
    height: 40px;
    flex: 1;
  }

  .instanceMenu__clearFiltersButtonBorder {
    @include instanceMenuItemBorder();
    background-color: var(--multi-guest-menu-cell-background-color);
    height: 40px;
    width: 36px;
  }

  .instanceMenu__clearFiltersButton {
    background-color: var(--multi-guest-menu-cell-background-color);
    border: none;
    border-radius: 0;
    color: var(--multi-guest-menu-text-color);
    height: 38px;
    width: 34px;
  }

  .instanceMenu__lastActivityFilterSelect {
    background-color: var(--multi-guest-menu-cell-background-color);
    border-width: 2px 0 0 1px;
    border-style: solid;
    border-color: var(--multi-guest-menu-cell-border-color);
    border-radius: 0;
    color: var(--multi-guest-menu-text-color);
    font-size: 12px;
    height: 40px;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 150px;
  }

  .instanceMenu__startTimeFilterSelect {
    background-color: var(--multi-guest-menu-cell-background-color);
    border-width: 2px 0 0 0;
    border-style: solid;
    border-color: var(--multi-guest-menu-cell-border-color);
    border-radius: 0;
    color: var(--multi-guest-menu-text-color);
    font-size: 12px;
    height: 40px;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 150px;
  }

  .instanceMenu__calendarFilterControls {
    display: flex;
    flex-wrap: nowrap;
    min-width: 547px;
  }

  .instanceMenu__searchBar {
    position: relative;
  }

  .instanceMenu__searchBarInput {
    background-color: var(--multi-guest-menu-cell-background-color);
    border-width: 2px 0 0 1px;
    border-style: solid;
    border-color: var(--multi-guest-menu-cell-border-color);
    color: var(--multi-guest-menu-text-color);
    font-family: var(--font-family);
    font-size: 12px;
    height: 40px;
    padding-left: 25px;
    width: 100%;
  }

  .instanceMenu__searchIcon {
    color: var(--multi-guest-menu-text-color);
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .instanceMenu__value {
    background-color: #3ba781;
    color: var(--multi-guest-menu-text-color);
    font-size: 12px;
    height: 40px;
    max-width: 96px;
  }

  .failedIds {
    margin-left: 15px;
  }
}
