:local {
  [data-theme="default"] {
    --guest-select-placeholdertext-color: #000;
  }

  [data-theme="dark"] {
    --guest-select-placeholdertext-color: #fff;
  }

  .guestSelect__placeholder_text_color {
    color: var(--guest-select-placeholdertext-color);
  }
}
