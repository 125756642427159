:local {
  [data-theme="default"] {
    --notifications-bg: hsl(0, 0%, 90%);
    --notifications-color: hsl(0, 0%, 30%);
  }

  [data-theme="dark"] {
    --notifications-bg: hsl(0, 0%, 10%);
    --notifications-color: hsl(0, 0%, 80%);
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .notifications__wrapper {
    position: fixed;
    right: 0;
    top: 75px;
    z-index: 100000000;
  }

  .notification__body {
    position: relative;
    z-index: 100;
    margin: 10px;
    padding: 25px 50px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);
    font-weight: 500;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @supports (backdrop-filter: blur(6px)) {
    .notification__body--blur {
      backdrop-filter: blur(6px);
    }
  }

  @supports not (backdrop-filter: blur(6px)) {
    .notification__body--blur {
      background-color: var(--th-bg-color);
    }
  }

  .notification__icon {
    position: absolute;
    left: 0;
    margin-left: 15px;
    fill: var(--notifications-color);
  }

  .notification__content {
    height: 100%;
    min-width: 300px;
    max-width: 300px;
  }

  .notification__close {
    position: absolute;
    right: 0px;
    margin-right: 10px;
  }

  .notification_type--general {
    color: var(--notifications-color);
    border-right: 3px solid var(--notifications-color);
  }

  .notification_type--error {
    color: var(--fire-color);
    border-right: 3px solid var(--fire-color);
  }

  .notification--dismissed {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}
