:local {
  [data-theme="default"] {
    --text-color: #1f3049;
    --icon-color: #1f3049;
  }

  [data-theme="dark"] {
    --text-color: white;
    --icon-color: white;
  }

  .directMessengerHeader__action {
    background: transparent;
    border: none;
    margin: 0 10px;
    color: var(--icon-color);
  }

  .directMessengerHeader__actionIcon {
    color: var(--icon-color);
  }

  .directMessengerHeader__name {
    margin-right: 20px;
    overflow: hidden;
    line-height: unset !important;
    color: var(--text-color);
  }

  .directMessengerHeader__initialsIcon {
    color: var(--icon-color);
  }
}
