:local {
  [data-theme="default"] {
    --guest-list-item-input-text: #000;
  }

  [data-theme="dark"] {
    --guest-list-item-input-text: #fff;
  }

  .guestListItemInput__input_text {
    color: var(--guest-list-item-input-text);
  }

  .guestListItemInput__button {
    background: transparent;
    border: none;
  }

  .guestListItemInput__text {
    color: var(--guest-list-item-input-text);
  }
}
