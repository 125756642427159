:local {
  [data-theme="default"] {
    --contact-header-info-color: hsl(0, 0%, 80%);
    --contact-header-icon-fill-color: hsl(0, 0%, 35%);
  }

  [data-theme="dark"] {
    --contact-header-info-color: hsl(0, 0%, 30%);
    --contact-header-icon-fill-color: hsl(0, 0%, 90%);
  }

  .confirmModal {
    display: flex;
    padding-top: 250px;
    padding-right: 100px;
    justify-content: center;
    text-align: center;
    align-items: center;

    hr {
      margin: 25px 0;
    }

    .modalHeader {
      font-size: 16px;
      width: 80%;
      padding-top: 10px;
      padding-bottom: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 0 auto;

      > header {
        padding-top: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      > span {
        width: 100%;
      }

      > svg {
        fill: var(--contact-header-icon-fill-color);
        margin-right: 15px;
      }

      .headerActions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin: 0 auto 0 0;

        > span {
          color: var(--contact-header-info-color);
          font-size: 16px;
          font-weight: 400;
          margin-right: 10px;
          text-transform: none;
        }
      }
    }

    .delete {
      padding-bottom: 20px;
    }

    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 2rem !important;
      height: 2rem !important;
      fill: var(--close-modal-color);
      transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.25);
      }
    }

    .confirmBtns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 25px;
    }

    .confirmBtn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .proceedButton {
      font-size: 12px;
      text-align: center;
      width: 150px;
      border-radius: 4px;
      text-transform: uppercase;
    }

    .cancelButton {
      font-size: 12px;
      text-align: center;
      width: 150px;
      border-radius: 4px;
      background-color: #a8b1bd;
      text-transform: uppercase;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  .tagLbl {
    > span {
      margin-bottom: 10px;
    }
  }
}
