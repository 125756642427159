:local {
  .container {
    max-height: 600px;
    overflow: auto;
  }

  .list {
    overflow-y: scroll;
  }

  .item {
    margin-left: 25px;
  }
}
