:local {
  [data-theme="default"] {
    --close-color: black;
    --calendar-bg-color: white;
    --quorum-banner-background-color: #b2b2c7;
    --quorum-banner-border-color: #695a94;
  }

  [data-theme="dark"] {
    --close-color: white;
    --calendar-bg-color: black;
    --quorum-banner-background-color: rgba(51, 50, 115, 0.3);
    --quorum-banner-border-color: #695a94;
  }

  .header {
    width: 100%;
    background: var(--quorum-banner-background-color);
    border: 1px solid var(--quorum-banner-border-color);
    display: flex;
    height: 48px;
    padding: 5px;
    justify-content: space-between;
  }

  .text {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--neutral-white);
    font-size: small;
  }

  .reminder_buttons {
    white-space: nowrap;
    margin-right: 5px;
  }

  .select_reminder_time {
    display: flex;
  }

  .timeInputs {
    display: flex;
    flex-direction: column;
  }

  .calendar {
    border: 1px solid var(--close-color);
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    z-index: 100000;
    background-color: var(--calendar-bg-color);
  }

  .close {
    margin-left: auto;
    color: var(--close-color);
  }

  .schedule_button {
    display: flex;
    justify-content: right;
  }
}
