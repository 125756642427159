:local {
  :root {
    --messenger-input-background: rgba(249, 249, 255, 0.08);
  }

  [data-theme="default"] {
    --messenger-text-area-background: #f5f7fa;
    --messenger-icon: #6a7381;
    --messenger-input-text-color: #000;
  }

  [data-theme="dark"] {
    --messenger-text-area-background: rgba(70, 83, 118, 0.88);
    --messenger-icon: var(--neutral-white);
    --messenger-input-text-color: #fff;
  }

  @supports (backdrop-filter: blur(8px)) {
    .messengerInput {
      background: var(--messenger-input-background);
      backdrop-filter: blur(8px);
      padding: 5px 15px 10px 15px;
      position: relative;
    }
  }

  @supports not (backdrop-filter: blur(8px)) {
    .messengerInput {
      background: var(--messenger-input-background);
      padding: 5px 15px 10px 15px;
      position: relative;
    }
  }

  .messengerInput__send {
    color: var(--messenger-icon);
    position: absolute;
    top: 55px;
    right: 20px;
    background-color: transparent;
    border: none;
  }

  .messengerInput__text_area {
    resize: none;
    width: 100%;
    height: 100%;
    background: var(--messenger-text-area-background);
    border: 0.3px solid #ebf0ff;
    box-sizing: border-box;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px 25px 10px 10px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    color: var(--messenger-input-text-color);
  }
}
