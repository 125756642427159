@import "../../style/grid";

:local {
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .controls {
      display: flex;
      align-items: center;

      > button {
        margin-right: 15px;
      }
    }

    .pages {
      display: flex;

      button {
        &.active {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      > .elipsis {
        align-self: center;
      }
    }
  }
  @include create-mq($grid__bp-sm - 1, "max") {
    .pagination {
      margin: 5px 5px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .controls {
        > button {
          margin-right: 4px;
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      .pages {
        > button {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }
  }
}
