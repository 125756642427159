:local {
  [data-theme="default"] {
    --instance-details-background-color: #f6f6f7;
  }

  [data-theme="dark"] {
    --instance-details-background-color: #3c404f;
  }

  .instanceDetails {
    background: var(--instance-details-background-color);
    width: 80%;
    height: 90%;
    padding: 0;
  }

  .instanceDetails__content {
    height: 100%;
    padding: 30px !important;
  }
}
