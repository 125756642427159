:local {
  [data-theme="default"] {
    --multi-guest-new-table-cell-background-color: #fafafa;
    --multi-guest-new-table-cell-border: 2px solid #dcdcdc;
  }

  [data-theme="dark"] {
    --multi-guest-new-table-cell-background-color: #04001b;
    --multi-guest-new-table-cell-border: 2px solid #464e6d;
  }

  .cell {
    background-color: var(--multi-guest-new-table-cell-background-color);
    border-bottom: var(--multi-guest-new-table-cell-border);
  }
}
