:local {
  .messengerBody {
    overflow: scroll;
    scroll-behavior: smooth;
    z-index: 2;
  }

  .messengerBody__header_spacer {
    margin-bottom: 20px;
  }
}
