:local {
  .integrationCard__button {
    margin-top: 15px;
    border-radius: 5px;
    min-height: 2.5em;
    min-width: 2.5em;
    padding: 0.5em;
    width: 100%;
    max-width: 15em;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: hsl(202, 94%, 57%);

    color: white;

    &:hover {
      background-color: hsl(202, 94%, 77%);
    }
  }
}
