@import "./spacing";

:root {
  // Deprecated
  --spacing-tiny: #{spacing(1)};
  --spacing-x-small: #{spacing(2)};
  --spacing-small: #{spacing(3)};
  --spacing-med: #{spacing(4)};
  --spacing-large: #{spacing(6)};
  --spacing-x-large: #{spacing(8)};
  --spacing-xx-large: #{spacing(10)};
  --spacing-xxx-large: #{spacing(12)};
  --spacing-jumbo: #{spacing(16)};
  --spacing-super-jumbo: #{spacing(24)};
  // New variables
  --kl-spacing-tiny: #{spacing(1)};
  --kl-spacing-x-small: #{spacing(2)};
  --kl-spacing-small: #{spacing(3)};
  --kl-spacing-med: #{spacing(4)};
  --kl-spacing-large: #{spacing(6)};
  --kl-spacing-x-large: #{spacing(8)};
  --kl-spacing-xx-large: #{spacing(10)};
  --kl-spacing-xxx-large: #{spacing(12)};
  --kl-spacing-jumbo: #{spacing(16)};
  --kl-spacing-super-jumbo: #{spacing(24)};
}
