:local {
  [data-theme="default"] {
    --integrations-info-txt-color: hsl(0, 0%, 30%);
    --integrations-env-url-input: var(--neutral-300);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-400);
  }

  [data-theme="dark"] {
    --integrations-info-txt-color: hsl(0, 0%, 60%);
    --integrations-env-url-input: var(--neutral-700);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-900);
  }

  .logo {
    width: 150px;
    height: 100px;
  }

  .reauth_container {
    width: 90%;
    display: flex;
  }
}
