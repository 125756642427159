@import "../../../components/spacing/spacing";

:local {
  .statusHeader {
    position: relative;
    height: size(12);
    padding: 10px;
  }

  .statusHeader__instance_id {
    position: absolute;
    left: spacing(4);
    top: 50%;
    transform: translateY(-50%);
  }

  .statusHeader_instance_id__title {
    color: #2e2f53;
  }

  .statusHeader_instance_id__value {
    color: #2e2f53;
  }

  .statusHeader__status {
    color: #2e2f53;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .statusHeader__value {
    max-width: fit-content;
    color: #2e2f53;
  }

  .statusHeader_value__text {
    color: var(--neutral-white);
    position: absolute;
    right: spacing(4);
    top: 50%;
    transform: translateY(-50%);
  }

  .greyed_out {
    color: #73778a;
  }
}
