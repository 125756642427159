:local {
  .list {
    position: relative;
  }

  .list__body {
    &--collapsed {
      overflow: hidden;
    }

    &--expanded {
      overflow: visible;
      height: 100% !important;
    }

    &--scrollable {
      overflow: scroll;
    }
  }

  .list__action {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 25%;
    border: none;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 1) 100%
    );

    &--expanded {
      position: relative;
      height: 50px;
      background: var(--team-routing-user-bg);
      border-radius: var(--border-radius);
    }
  }

  .list_action__icon {
    position: absolute;
    bottom: 0;
    color: white;
  }
}
