:local {
  [data-theme="default"] {
    --multi-guest-table-cell-background-color: #fafafa;
    --multi-guest-table-cell-border: 2px solid #dcdcdc;
    --multi-guest-table-cell-checkbox-background-color: hsl(0, 0%, 95%);
    --multi-guest-table-cell-checkbox-border: 1px solid #49505a;
    --tags-border-color: #000;
  }

  [data-theme="dark"] {
    --multi-guest-table-cell-background-color: #04001b;
    --multi-guest-table-cell-border: 2px solid #464e6d;
    --multi-guest-table-cell-checkbox-background-color: #464e6d;
    --multi-guest-table-cell-checkbox-border: 1px solid #191c3a;
    --tags-border-color: #fff;
  }

  .tableCells {
    background-color: var(--multi-guest-table-cell-background-color);
    border-bottom: var(--multi-guest-table-cell-border);
  }

  .toggleMeeting__checkbox {
    background-color: var(--multi-guest-table-cell-checkbox-background-color);
    border: var(--multi-guest-table-cell-checkbox-border);
    width: 25px;
    height: 25px;
    margin-bottom: 15px;
  }

  .meetingType__container--disabled {
    font-style: italic;
    opacity: 0.25;
  }

  .lastActivity {
    padding: 5%;
  }

  .lastActivity__text {
    margin-bottom: 10px;
  }

  .lastActivity__systemNotes {
    text-align: center;
  }

  .tags__button {
    background-color: transparent;
    border: 1px solid var(--tags-border-color);
  }
}
