[data-theme="default"] {
  --tooltip-bg-color: hsl(0, 0%, 29%);
  --tooltip-color: hsl(0, 0%, 90%);
}

[data-theme="dark"] {
  --tooltip-bg-color: hsl(0, 0%, 1%);
  --tooltip-color: hsl(0, 0%, 90%);
}

.react-hint {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: 0.7s fadeIn;

  &:after {
    content: "";
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid transparent;
  }
}

.react-hint__content {
  padding: 10px;
  border-radius: 5px;
  background: var(--tooltip-bg-color);
  color: var(--tooltip-color);
  font-size: 12px;
}

.react-hint--top:after {
  top: auto;
  border-bottom: none;
  border-top-color: var(--tooltip-bg-color);
}

.react-hint--left:after {
  left: auto;
  border-right: none;
  border-left-color: var(--tooltip-bg-color);
}

.react-hint--right:after {
  right: auto;
  border-left: none;
  border-right-color: var(--tooltip-bg-color);
}

.react-hint--bottom:after {
  bottom: auto;
  border-top: none;
  border-bottom-color: var(--tooltip-bg-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
