:local {
  [data-theme="default"] {
    --reporting-background-color: #e4e8ee;
    --reporting-blur: blur(4px);
  }

  [data-theme="dark"] {
    --reporting-background-color: rgba(51, 50, 115, 0.53);
    --reporting-blur: unset;
  }

  @supports (backdrop-filter: var(--reporting-blur)) {
    .reporting {
      background-color: var(--reporting-background-color);
      backdrop-filter: var(--reporting-blur);
      padding: 15px;
    }
  }

  @supports not (backdrop-filter: var(--reporting-blur)) {
    .reporting {
      background-color: var(--reporting-background-color);
      padding: 15px;
    }
  }

  .reporting__body {
    padding-top: 10px;
  }
}
