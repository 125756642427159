:local {
  [data-theme="default"] {
    --attendees-guests-background-color: #fafafa;
    --attendees-guest-border: 2px solid #dcdcdc;
  }

  [data-theme="dark"] {
    --attendees-guests-background-color: #04001b;
    --attendees-guest-border: 2px solid #464e6d;
  }

  .guests {
    background-color: var(--attendees-guests-background-color);
    min-height: 128px;
    padding-left: 15px;
  }
}
