:local {
  [data-theme="default"] {
    --integrations-info-txt-color: hsl(0, 0%, 30%);
    --integrations-env-url-input: var(--neutral-300);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-400);
  }

  [data-theme="dark"] {
    --integrations-info-txt-color: hsl(0, 0%, 60%);
    --integrations-env-url-input: var(--neutral-700);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-900);
  }

  .logo {
    width: 125px;
    height: 100px;
  }

  .env_url_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: #c5e2f6;
    border-radius: 10px;
    box-shadow: none;
  }

  .env_url_body {
    color: #000000;
  }

  .env_url_content_style {
    bottom: 65px;
    height: 125px;
    position: absolute;
    right: -70px;
    width: 305px;
    z-index: 2;
  }

  .env_url_input {
    color: var(--integrations-env-url-input-color);
    margin-top: 15px;
    margin-left: 30px;
    height: 37px;
    width: 80%;
    text-align: center;
    background-color: var(--integrations-env-url-input);
    border: 1px solid var(--integrations-env-url-input);
    box-shadow: inset 1px 1px 2px var(--integrations-env-url-input-box-shadow);
    border-radius: 3px;

    &::placeholder {
      color: var(--integrations-env-url-input-color);
    }
  }

  .help_icon {
    margin-top: 25px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--integrations-env-url-input-color);
  }

  .triangleDown {
    position: absolute;
    width: 0;
    height: 0;
    right: 65px;
    top: 120px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 30px solid #c5e2f6;
  }

  .reauth_container {
    width: 90%;
    display: flex;
  }
}
