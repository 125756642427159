:root {
  --neutral-100: #f8f9fb;
  --neutral-200: #eef1f6;
  --neutral-300: #e4e8ee;
  --neutral-400: #cdd3dd;
  --neutral-500: #a8b1bd;
  --neutral-600: #6a7381;
  --neutral-700: #49505a;
  --neutral-800: #1f2329;
  --neutral-900: #121417;
  --neutral-black: #000000;
  --neutral-white: #ffffff;
  --neutral-charcoal: #434343;
}
