@import "./_dark";
@import "./_light";

:root {
  [data-theme="default"] {
    --kl-shadow--xl: #{$kl-light-shadow--xl};
    --kl-shadow--lg: #{$kl-light-shadow--lg};
    --kl-shadow--md: #{$kl-light-shadow--md};
    --kl-shadow--sm: #{$kl-light-shadow--sm};
    --kl-shadow--xs: #{$kl-light-shadow--xs};
  }

  [data-theme="dark"] {
    --kl-shadow--xl: #{$kl-dark-shadow--xl};
    --kl-shadow--lg: #{$kl-dark-shadow--lg};
    --kl-shadow--md: #{$kl-dark-shadow--md};
    --kl-shadow--sm: #{$kl-dark-shadow--sm};
    --kl-shadow--xs: #{$kl-dark-shadow--xs};
  }

  .kl-shadow {
    &--xl {
      box-shadow: var(--kl-shadow--xl);
    }

    &--lg {
      box-shadow: var(--kl-shadow--lg);
    }

    &--md {
      box-shadow: var(--kl-shadow--md);
    }

    &--sm {
      box-shadow: var(--kl-shadow--sm);
    }

    &--xs {
      box-shadow: var(--kl-shadow--xs);
    }
  }
}
