:local {
  [data-theme="default"] {
    --tags-background-color: #e4e8ee;
    --tags-action-border-color: #49505a;
  }

  [data-theme="dark"] {
    --tags-background-color: rgba(8, 12, 50, 0.88);
    --tags-action-border-color: #b8c5d3;
  }

  .tags {
    background-color: var(--tags-background-color);
    padding: 15px;
  }

  .tags_body__action {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--tags-action-border-color);
  }
}
