$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;
$gutter: 25;

$map-grid-props: (
  "": 0,
  "-sm": $grid__bp-sm,
  "-md": $grid__bp-md,
  "-lg": $grid__bp-lg,
  "-xl": $grid__bp-xl,
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    .col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: calc(100 / calc($grid-cols / $i)) * 1%;
        max-width: calc(100 / calc($grid-cols / $i)) * 1%;
        padding: 0 calc($gutter/2) * 1px;
      }

      .col#{$modifier}-offset-#{$i} {
        margin-left: calc(100 / calc($grid-cols / $i)) * 1%;
        padding: 0 calc($gutter/2) * 1px;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == "") {
    $modifier: "-xs";
  }
  @include create-mq($breakpoint - 1, "max") {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, "min") {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: $grid__bp-md * 1px;
  margin: 0 auto;

  &.is--fluid {
    margin: 0;
    max-width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}
