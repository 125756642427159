@import "../theme/theme";

:local {
  [data-theme="default"] {
    --dropdown-background-color: var(--kl-primary-color);
  }

  [data-theme="dark"] {
    --dropdown-background-color: var(--kl-primary-color);
  }
}
