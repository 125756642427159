:local {
  [data-theme="default"] {
    --kl-input-background-color: var(--kl-light-shade);
    --kl-input-border-color: var(--kl-primary-300);
    --kl-input-disabled-background-color: var(--kl-neutral-300);
    --kl-input-disabled-text-color: var(--kl-neutral-400);
    --kl-input-placeholder-text-color: var(--kl-neutral-400);

    // Deprecated
    --input-background-color: var(--neutral-white);
    --input-border-color: var(--neutral-500);
    --input-disabled-background-color: var(--neutral-300);
    --input-disabled-color: var(--neutral-700);
    --input-font-color: var(--text-dark-50-color);
    --input-placeholder-color: var(--neutral-400);
  }

  [data-theme="dark"] {
    --kl-input-background-color: var(--kl-dark-700);
    --kl-input-border-color: var(--kl-dark-900);
    --kl-input-disabled-background-color: var(--kl-neutral-700);
    --kl-input-disabled-text-color: var(--kl-neutral-400);
    --kl-input-placeholder-text-color: var(--kl-neutral-400);

    // Deprecated
    --input-background-color: var(--neutral-black);
    --input-border-color: var(--neutral-800);
    --input-disabled-background-color: var(--neutral-black);
    --input-disabled-color: var(--neutral-700);
    --input-font-color: var(--text-light-50-color);
    --input-placeholder-color: var(--neutral-700);
  }
}
