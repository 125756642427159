:local {
  [data-theme="default"] {
    --meeting-details-background-color: #e9e9eb;
  }

  [data-theme="dark"] {
    --meeting-details-background-color: #030517;
  }

  .meetingDetails {
    background: var(--meeting-details-background-color);
    margin-left: 5px;
  }
}
