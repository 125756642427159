@import "../../../../style/mixins";
@import "../cards";

:local {
  [data-theme="default"] {
    --instance-final-card-by-color: inheirt;
  }

  [data-theme="dark"] {
    --instance-final-card-by-color: #0c0a25;
  }

  .instanceFinal {
    flex: 1 1 25%;
    padding: 5px;
  }

  .instanceFinal__card {
    background-color: var(--instance-final-card-by-color);
  }

  .instanceFinal__card_content {
    min-height: 140px;
    padding: 0 15%;
  }

  .instanceFinal__text {
    flex: 3 3 75%;
    padding-bottom: $text-above-value-padding;
  }

  .instanceFinal__text_total {
    flex: 3 3 75%;
    padding: 0 5% $text-above-value-padding 0;
  }

  .instanceFinal__value {
    color: $text-color-instance-stages;
    flex: 1 1 25%;
    justify-content: flex-start;
    padding-bottom: $text-above-value-padding;

    &:hover {
      @include hover-underline;
    }
  }
}
