@import "../../style/helpers.scss";

:local {
  .tagManagement__container {
    height: 100%;
    width: 100%;
  }

  .tagManagement__cell {
    padding: 0.785em 1.15em;
  }

  .tagManagement__overlay {
    height: 100%;
    width: calc(100% - 37.725em);
  }

  .tagManager__createMenuRow {
    display: flex;
    align-items: center;
    background-color: inherit;
    justify-content: space-between;
    position: sticky;
    width: 100%;
    z-index: 2;
  }

  .tagManager__drawer {
    box-shadow: var(--kl-shadow--xl);
    font-size: 16px;
    height: 100%;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 37.725em;
    animation-timing-function: ease-in-out;
  }

  .tagManager__menu {
    background-color: var(--drawer-bg-color);
    position: sticky;
    z-index: 4;
    top: -1px;
  }

  .tagManager__searchBar {
    min-width: 18em;
  }

  .tagManager--slidein {
    animation-duration: 0.5s;
    animation-name: slidein;
    transform: translateX(0%);
  }

  .tagManager--slideout {
    animation-duration: 0.5s;
    animation-name: slideout;
    transform: translateX(100%);
  }

  @keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }

  .tagManager__input {
    padding: 1.15em;
    padding-right: 2em;
  }

  .tagManager__text {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.15em;
    max-width: 22em;
    white-space: pre;
  }

  .tagManager__headerCell {
    position: sticky;
    z-index: 5;
  }

  .tagManager__cell {
    padding: 0.785em 1.15em;
  }

  .tagManager__deleteAndSearchMenuRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
  }

  .tagManager__deleteAndSearchMenuRowButtons {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .tagManager__tagCount {
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  }

  .tagManagerRow__buttons {
    display: flex;
    padding: 1.15em;
  }

  .tagManager__menuCellIcon {
    color: var(--primary-text-color);
  }

  .tagManager__pagination {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    margin-left: 1em;
  }

  .tagManager__loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
