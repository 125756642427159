@import "./typography";

:root {
  // Deprecated
  --body-font-size: #{$base-font-size};
  --font-family: #{$base-font-family};
  --x-small-font-size: 12px;
  --tiny-font-size: 10px;
  // Newer
  --kl-font-size: #{$base-font-size};
  --kl-font-family: #{$base-font-family};

  [data-theme="default"] {
    --kl-font-color: var(--kl-shade-light);
  }

  [data-theme="dark"] {
    --kl-font-color: var(--kl-shade-dark);
  }
}
