:local {
  [data-theme="default"] {
    --multi-guest-menu-cell-search-background-color: #fff;
    --multi-guest-menu-cell-border-color: #dcdcdc;
    --multi-guest-menu-text-color: #000;
    --chip-selected-color: #2baef9;
  }

  [data-theme="dark"] {
    --multi-guest-menu-cell-search-background-color: #191c3a;
    --multi-guest-menu-cell-border-color: #464e6d;
    --multi-guest-menu-text-color: #fff;
    --chip-selected-color: #2baef9;
  }

  .filterButton {
    user-select: none;
    font-size: smaller;
    border: 0;
    padding: 0.4em;
    border-radius: 2px;
    cursor: pointer;
  }

  .filterButton_selected {
    background-color: var(--chip-selected-color) !important;
  }

  .searchBar {
    height: 100%;
    width: 100%;
    position: relative;
    border-top: solid;
    border-width: 2px;
    border-color: var(--multi-guest-menu-cell-border-color);
  }

  .searchBar__form {
    height: 100%;
    width: 100%;
  }

  .searchBar__formLabel {
    background-color: var(--multi-guest-menu-cell-search-background-color);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  .searchBar__formLabelInput {
    color: var(--multi-guest-menu-text-color);
    font-family: var(--kl-font-family);
    border: 0;
    background-color: transparent;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    // !important is needed to overwrite internal MUI classnames
    margin-top: -2px !important;
    margin-bottom: 0 !important;
  }

  .searchBar__formLabelIcon {
    color: var(--multi-guest-menu-text-color);
  }

  .searchBar__formLabelP {
    color: lightgrey;
    font-size: smaller;
    white-space: nowrap;
    justify-self: flex-end;
    padding-right: 0.5em;
  }

  .searchBar__closeButton {
    position: relative;
    float: right;
    top: 0.5em;
    right: 0.5em;
    border: none;
    background-color: var(--multi-guest-menu-cell-search-background-color);
    color: var(--multi-guest-menu-text-color);
  }

  .searchBar__closeButtonIcon {
    background-color: var(--multi-guest-menu-cell-search-background-color);
  }

  .searchBar__dropdown {
    position: relative;
    display: inline-block;
    z-index: 99;
    background-color: var(--multi-guest-menu-cell-search-background-color);
  }

  .searchBar__category {
    padding: 0.5em;
    font-size: small;
  }

  .searchBar__filtersContainer {
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.75em;
  }

  .searchBar__section {
    margin-bottom: 1em;
  }

  .searchBar__recentSearchesList {
    padding: 3em;
    list-style-type: none; /* Remove bullets */
  }

  .searchBar__recentSearchesListItem {
    font-size: smaller;
    padding: 0.5em;
    user-select: none;
    list-style-type: none; /* Remove bullets */
  }

  .searchBar__recentSearchesListItemButton {
    background-color: transparent;
    border-width: 0;
    color: var(--multi-guest-menu-text-color);
    height: 100%;
    padding-bottom: 0.25em;
    padding-left: 0.5em;
    padding-top: 0.25em;
    text-align: left;
    width: 100%;
  }

  .searchBar__recentSearchesEmptyListItem {
    font-size: smaller;
    padding: 0.5em;
    user-select: none;
    list-style-type: none; /* Remove bullets */
    color: lightgrey;
  }

  .searchBar__recentSearchesListItem:hover {
    background-color: skyblue;
  }
}
