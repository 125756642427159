:local {
  [data-theme="default"] {
    --close-icon-color: #000;
  }

  [data-theme="dark"] {
    --close-icon-color: #fff;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
  }

  .close__icon {
    color: var(--close-icon-color);
  }
}
