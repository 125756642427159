.textarea {
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  color: var(--meetingTypes-text-color);
  flex: 1;
  font-size: var(--font-size-large);
  font-family: var(--font-family);
  overflow: scroll;
  // max-height: 520px;
  padding: 5px 12px;
}
