@import "./dark";
@import "./error";
@import "./neutrals";
@import "./primaries";
@import "./secondaries";
@import "./shades";
@import "./success";
@import "./warning";

:root {
  --kl-dark-50: #{$kl-dark-50};
  --kl-dark-100: #{$kl-dark-100};
  --kl-dark-200: #{$kl-dark-200};
  --kl-dark-300: #{$kl-dark-300};
  --kl-dark-400: #{$kl-dark-400};
  --kl-dark-500: #{$kl-dark-500};
  --kl-dark-600: #{$kl-dark-600};
  --kl-dark-700: #{$kl-dark-700};
  --kl-dark-800: #{$kl-dark-800};
  --kl-dark-900: #{$kl-dark-900};
  --kl-error-50: #{$kl-error-50};
  --kl-error-100: #{$kl-error-100};
  --kl-error-200: #{$kl-error-200};
  --kl-error-300: #{$kl-error-300};
  --kl-error-400: #{$kl-error-400};
  --kl-error-500: #{$kl-error-500};
  --kl-error-600: #{$kl-error-600};
  --kl-error-700: #{$kl-error-700};
  --kl-error-800: #{$kl-error-800};
  --kl-error-900: #{$kl-error-900};
  --kl-neutral-50: #{$kl-neutral-50};
  --kl-neutral-100: #{$kl-neutral-100};
  --kl-neutral-200: #{$kl-neutral-200};
  --kl-neutral-300: #{$kl-neutral-300};
  --kl-neutral-400: #{$kl-neutral-400};
  --kl-neutral-500: #{$kl-neutral-500};
  --kl-neutral-600: #{$kl-neutral-600};
  --kl-neutral-700: #{$kl-neutral-700};
  --kl-neutral-800: #{$kl-neutral-800};
  --kl-neutral-900: #{$kl-neutral-900};
  --kl-primary-50: #{$kl-primary-50};
  --kl-primary-100: #{$kl-primary-100};
  --kl-primary-200: #{$kl-primary-200};
  --kl-primary-300: #{$kl-primary-300};
  --kl-primary-400: #{$kl-primary-400};
  --kl-primary-500: #{$kl-primary-500};
  --kl-primary-600: #{$kl-primary-600};
  --kl-primary-700: #{$kl-primary-700};
  --kl-primary-800: #{$kl-primary-800};
  --kl-primary-900: #{$kl-primary-900};
  --kl-secondary-50: #{$kl-secondary-50};
  --kl-secondary-100: #{$kl-secondary-100};
  --kl-secondary-200: #{$kl-secondary-200};
  --kl-secondary-300: #{$kl-secondary-300};
  --kl-secondary-400: #{$kl-secondary-400};
  --kl-secondary-500: #{$kl-secondary-500};
  --kl-secondary-600: #{$kl-secondary-600};
  --kl-secondary-700: #{$kl-secondary-700};
  --kl-secondary-800: #{$kl-secondary-800};
  --kl-secondary-900: #{$kl-secondary-900};
  --kl-light-shade: #{$kl-light-shade};
  --kl-dark-shade: #{$kl-dark-shade};
  --kl-success-50: #{$kl-success-50};
  --kl-success-100: #{$kl-success-100};
  --kl-success-200: #{$kl-success-200};
  --kl-success-300: #{$kl-success-300};
  --kl-success-400: #{$kl-success-400};
  --kl-success-500: #{$kl-success-500};
  --kl-success-600: #{$kl-success-600};
  --kl-success-700: #{$kl-success-700};
  --kl-success-800: #{$kl-success-800};
  --kl-success-900: #{$kl-success-900};
  --kl-warning-50: #{$kl-warning-50};
  --kl-warning-100: #{$kl-warning-100};
  --kl-warning-200: #{$kl-warning-200};
  --kl-warning-300: #{$kl-warning-300};
  --kl-warning-400: #{$kl-warning-400};
  --kl-warning-500: #{$kl-warning-500};
  --kl-warning-600: #{$kl-warning-600};
  --kl-warning-700: #{$kl-warning-700};
  --kl-warning-800: #{$kl-warning-800};
  --kl-warning-900: #{$kl-warning-900};
}
