:root {
  --text-dark-color: #000000;
  --text-light-color: #ffffff;
  --text-dark-50-color: #49505a;
  --text-light-50-color: #cdd3dd;
}

[data-theme="default"] {
  --text-color: var(--text-dark-color);
  --text-50-color: var(--text-dark-50-color);
}

[data-theme="dark"] {
  --text-color: var(--text-light-color);
  --text-50-color: var(--text-light-50-color);
}
