:local {
  .newMeetingRow__container {
    position: sticky;
    top: 120px;
    z-index: 1;
    min-height: 216px;
    display: grid;
    background: var(--meeting-table-header-cell-color);
    border-bottom: 3px solid var(--border-color);
    grid-auto-rows: minmax(26px, 100%);
    grid-template-columns: minmax(72px, 5.5%) // Toggle
      minmax(72px, 5.5%) // Active
      minmax(80px, 6%) // Start Time
      minmax(177px, 13%) // Meeting Types
      minmax(552px, 47%) // Attendees (Instance, Guest, Sequence)
      minmax(140px, 11%) // Last Activity
      minmax(104px, 7%) // Tags
      minmax(93px, 5%); // Cost
  }

  .newMeetingTableRow__dropdown {
    color: var(--primary-text-color);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .newMeetingTableRow__value {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
