@import "../cards";

:local {
  [data-theme="default"] {
    --guest-final-card-background-color: hsl(0, 0%, 99%);
    --guest-final-card-shadows: 0px 2px 8px rgba(87, 102, 117, 0.1);
  }

  [data-theme="dark"] {
    --guest-final-card-background-color: #0c0a25;
    --guest-final-card-shadows: 0 2px 8px rgba(2, 2, 2, 0.61);
  }

  .guestFinal {
    min-width: 25%;
    padding: 5px;

    .guestFinal__card {
      position: relative;
      width: 100%;
      height: 160px;
      background-color: var(--guest-final-card-background-color);
      box-shadow: var(--guest-final-card-shadows);
      border-radius: var(--border-radius);
      transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
        0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    }
  }

  .loading {
    padding: 0;
  }

  .accept_box {
    display: flex;
    justify-content: space-around;
    font-size: large;
    color: #3ba781;
    text-align: center;
  }

  .accept_percentage {
    margin-left: 60px;
  }

  .final_button {
    font-size: x-small;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}
