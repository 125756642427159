:local {
  [data-theme="default"] {
    --icon-color: hsl(0, 0%, 80%);
    --bg: hsl(0, 0%, 95%);
    --loading-table-view-bg: hsl(0, 0%, 90%);
    --loading-table-view-bg-highlight: hsl(0, 0%, 95%);
  }

  [data-theme="dark"] {
    --icon-color: hsl(0, 0%, 20%);
    --bg: hsl(0, 0%, 10%);
    --loading-table-view-bg: #252945;
    --loading-table-view-bg-highlight: #464e6d;
  }

  .emptyView {
    padding: 25px 0 50px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: var(--bg);

    > .actions {
      margin-top: 25px;
      display: flex;

      > button {
        margin-right: 10px;
      }
    }

    > svg {
      fill: var(--icon-color);
    }

    .viewName {
      color: var(--primary-color);
      margin-right: 10px;
      text-transform: capitalize;
    }
  }

  .skeleton {
    position: relative;
    margin-bottom: 3px;
    overflow: hidden;
    width: 100%;
    background: var(--loading-table-view-bg);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        var(--loading-table-view-bg),
        var(--loading-table-view-bg-highlight),
        var(--loading-table-view-bg)
      );
      animation: progress 1s ease-in-out infinite;
    }
  }

  @keyframes progress {
    0% {
      transform: translate3d(-100%, 0, 0);
    }

    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
}
