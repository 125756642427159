:local {
  .tagAutomationCard {
    margin-top: 25px;
    display: flex;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 40px;
  }

  .tagAutomationCard__text {
    min-width: 145px;
    max-width: 145px;
    padding-right: 10px;
    text-align: center;
    margin-right: -2px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
  }

  .tagAutomationCard__taggingArea {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .tagAutomationCard__object {
    width: 50%;
    display: flex;
  }

  .tagAutomationCard__tagTypeTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 5px;
  }

  .tags {
    margin-bottom: auto;
    margin-top: auto;
  }
}
