:local {
  [data-theme="default"] {
    --initials-icon-border-color: #49505a;
  }

  [data-theme="dark"] {
    --initials-icon-border-color: #fff;
  }

  $icon-margin-right: 10px;

  .quorumIcon {
    margin-right: $icon-margin-right;
  }

  .quorumIcon__icon {
    background-color: transparent;
    border: 1px solid rgba(124, 123, 171, 0.58);
    border-radius: 50%;
  }

  .quorumIcon__text {
    color: rgba(124, 123, 171, 0.58);
  }

  .initialsIcon__icon {
    position: relative;
    background-color: transparent;
    border: 1px solid var(--initials-icon-border-color);
    border-radius: 50%;
    margin-right: $icon-margin-right;
  }

  .initialsIcon__status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border: 1px solid var(--initials-icon-border-color);
    border-radius: 50%;

    &--staging {
      background-color: #c1d6ef;
    }
  }
}
