:local {
  [data-theme="default"] {
    --meeting-type-details-header-text: #1f3049;
  }

  [data-theme="dark"] {
    --meeting-type-details-header-text: #fff;
  }

  .meetingTypeDetailsHeaderInput__input_text {
    width: 100%;
    justify-content: center;
  }

  .meetingTypeDetailsHeaderInput__text {
    height: fit-content;
    line-height: unset !important;
    color: var(--meeting-type-details-header-text);
  }

  .meetingTypeDetailsHeaderInput__location {
    height: fit-content;
    line-height: unset !important;
    margin-top: 5px;
    word-break: break-all;
  }
}
