@import "../../style/grid";
@import "../theme";

:local {
  [data-theme="default"] {
    --meeting-instance-pagination-button-bg-color: #fff;
    --meeting-instance-pagination-button-color: #49505a;
    --meeting-instance-pagination-button-border: 1px solid #e4e8ee;
  }

  [data-theme="dark"] {
    --meeting-instance-pagination-button-bg-color: #252945;
    --meeting-instance-pagination-button-color: #464e6d;
    --meeting-instance-pagination-button-border: 1px solid #464e6d;
  }

  .pageNumbers {
    color: var(--primary-text-color);
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .pageNumbers--active {
    color: var(--primary-blue);
  }

  .pagination {
    display: flex;
    flex-direction: row;
    height: 32px;
    gap: 16px;
    justify-content: space-between;

    .controls {
      display: flex;
      align-items: center;
    }

    .pages {
      display: flex;
      font-size: 7px;

      button {
        &.active {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      > .elipsis {
        align-self: center;
      }
    }
  }

  .firstPage__button {
    background-color: var(--meeting-instance-pagination-button-bg-color);
    color: var(--meeting-instance-pagination-button-color);
    border: var(--meeting-instance-pagination-button-border);
  }

  .prevPage__button {
    background-color: var(--meeting-instance-pagination-button-bg-color);
    color: var(--meeting-instance-pagination-button-color);
    border: var(--meeting-instance-pagination-button-border);
    margin-left: 10px;
    margin-right: 15px;
  }

  .nextPage__button {
    background-color: var(--meeting-instance-pagination-button-bg-color);
    color: var(--meeting-instance-pagination-button-color);
    border: var(--meeting-instance-pagination-button-border);
    margin-left: 15px;
    margin-right: 10px;
  }

  .lastPage__button {
    background-color: var(--meeting-instance-pagination-button-bg-color);
    color: var(--meeting-instance-pagination-button-color);
    border: var(--meeting-instance-pagination-button-border);
  }
}
