:root {
  --primary-blue: #2baef9;
  --primary-orange: #dd5832;
  --primary-light-gray: #eeeeee;
  --primary-dark-gray: #434343;
  --primary-purple: #252945;
  --primary-purple-transparent: #1a252945;
  --primary-green: #02ba86;
  --primary-yellow: #ffda6c;
  --primary-white: #ffffff;
  --secondary-purple: #191c3a;
}
