:local {
  [data-theme="default"] {
    --instance-details-logs-background-color: rgba(255, 255, 255, 0.88);
    --instance-details-logs-border: 0.5px solid #a8b1bd;
  }

  [data-theme="dark"] {
    --instance-details-logs-background-color: rgba(8, 12, 50, 0.88);
    --instance-details-logs-border: unset;
  }

  .systemNotes {
    background-color: var(--instance-details-logs-background-color);
    border: var(--instance-details-logs-border);
    padding: 15px;
  }

  .systemNotes__header {
    margin-bottom: 10px;
  }

  .logs {
    overflow: scroll;
  }
}
