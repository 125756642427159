@import "../../../../style/mixins";
@import "../cards";

:local {
  [data-theme="default"] {
    --guest-provisioning-card-by-color: inheirt;
  }

  [data-theme="dark"] {
    --guest-provisioning-card-by-color: #0c0a25;
  }

  .guestProvisioning {
    flex: 1 1 25%;
    padding: 5px;
  }

  .guestProvisioning__card {
    background-color: var(--guest-provisioning-card-by-color);
  }

  .guestProvisioning__card_content {
    min-height: 140px;
  }

  .guestProvisioning__text {
    padding-bottom: $text-above-value-padding;
  }

  .guestProvisioning__value {
    color: var(--primary-text-color);

    &:hover {
      @include hover-underline;
    }
  }
}
