@import "../../style/helpers.scss";

:local {
  [data-theme="default"] {
    --checkbox-plate-color: #ffffff;
    --checkbox-border-color: #cdd3dd;
  }

  [data-theme="dark"] {
    --checkbox-plate-color: #000000;
    --checkbox-border-color: #49505a;
  }

  :root {
    --checkbox-height: 16px;
    --checkbox-width: 16px;
    --checkbox-hover-color: var(--primary-color);
    --checkbox-check-color: var(--primary-color);
  }

  input.checkbox__input {
    appearance: none;
    border: 0;
    cursor: pointer;
    height: var(--checkbox-height);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: var(--checkbox-width);
    z-index: 2;
    opacity: 0;
  }

  .checkbox__container {
    position: relative;
  }

  .checkbox__plate {
    background-color: var(--checkbox-plate-color);
    border: 1px solid #49505a;
    border-radius: 2px;
    height: var(--checkbox-height);
    width: var(--checkbox-width);
  }

  .checkbox__check {
    background-color: var(--checkbox-check-color);
    border-radius: 2px;
    height: var(--checkbox-height);
    width: var(--checkbox-width);

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 0px;
      top: 0px;
      height: var(--checkbox-height);
      width: var(--checkbox-width);
      border: solid var(--chk-checkmark-color);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .checkbox__plate--highlight {
    border: 1px solid var(--checkbox-hover-color);
  }
}
