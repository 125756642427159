:local {
  [data-theme="default"] {
    --integrations-info-txt-color: hsl(0, 0%, 30%);
    --integrations-env-url-input: var(--neutral-300);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-400);
  }

  [data-theme="dark"] {
    --integrations-info-txt-color: hsl(0, 0%, 60%);
    --integrations-env-url-input: var(--neutral-700);
    --integrations-env-url-input-color: var(--neutral-500);
    --integrations-env-url-input-box-shadow: var(--neutral-900);
  }

  .integrationCard__disconnectButton {
    background-color: var(--chilean-fire-color);
    color: white;

    &:hover {
      background-color: var(--chilean-fire-color-lighter);
    }
  }

  .integrationCard__sandboxButton {
    background-color: var(--integrations-env-url-input);
    color: var(--integrations-env-url-input-color);
  }

  .integrationCard__deleteButton {
    margin-top: 15px;
    border-radius: 5px;
    margin-left: 10px;
    min-height: 2.5em;
    min-width: 2.5em;
    padding: 0.5em;

    background-color: var(--chilean-fire-color);
    color: white;

    &:hover {
      background-color: var(--chilean-fire-color-lighter);
    }
  }

  .integrationCard_configurationButton {
    font-size: x-small;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}
