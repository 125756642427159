:local {
  [data-theme="default"] {
    --instance-id-color: #056da8;
    --user-attribute-change-color: #2baef9;
  }

  [data-theme="dark"] {
    --instance-id-color: #5dc1fa;
    --user-attribute-change-color: #ffffff;
  }

  .logBuilder__instanceId {
    color: var(--instance-id-color);
  }

  .logBuilder__userAttributeChange {
    color: var(--user-attribute-change-color);
  }

  .logBuilder__bold {
    font-weight: bold;
  }

  .logBuilder__italics {
    font-style: italic;
  }
}
