:local {
  [data-theme="default"] {
    --dropdown-indicator-color: var(--neutral-black);
  }

  [data-theme="dark"] {
    --dropdown-indicator-color: var(--neutral-white);
  }

  .select__dropdownIndicator {
    align-items: center;
    color: var(--dropdown-indicator-color);
    display: flex;
    justify-content: center;
    width: 36px;
  }

  .select__dropdownIndicatorButton {
    cursor: pointer;
  }
}
