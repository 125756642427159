:local {
  .overlay--transparent {
    background-color: rgba(#ffffff, 0);
  }

  .overlay--blurred {
    backdrop-filter: blur(5px);
    height: 100%;
    width: 100%;
  }

  .overlay--fullscreen {
    height: 100%;
    width: 100%;
  }

  .overlay--opauqe {
    background-color: rgba(#333333, 0.6);
    height: 100%;
    width: 100%;
  }

  .drawer__overlay {
    background-color: rgba(#ffffff, 0);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .drawer__content {
    padding: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .default_overlay {
    background-color: rgba(#ffffff, 0);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .default__content {
    padding: 0;
    z-index: 1001;
    background-color: transparent;
  }
}
