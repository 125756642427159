:local {
  [data-theme="default"] {
    --background-color: hsl(0, 0%, 80%);
  }

  [data-theme="dark"] {
    --background-color: hsl(0, 0%, 30%);
  }

  .integrations {
    margin-top: 10px;
    padding: 45px;
  }

  .integration {
    margin-top: 10px;
    height: 360px;
    width: 340px;
    margin-right: var(--spacing-tiny);

    &.disabled {
      opacity: 1;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-content: space-between;

    > header {
      display: flex;
      align-items: center;
      height: 100px;
      min-height: 100px;
      text-align: center;
      align-self: center;
      margin: 40px auto;
    }

    > footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      margin-top: 10px;
      width: 100%;

      > button {
        width: 80%;
        margin-top: 15px;
        border-radius: 3px;
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
  }

  .help_icon {
    margin-top: 25px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--integrations-env-url-input-color);
  }

  .integrationsContainer {
    display: flex;
    justify-content: space-between;
  }

  .connectionStatus {
    position: absolute;
  }

  .buttonContainer {
    display: flex;
    width: 80%;
  }

  .reauth_button {
    margin-top: 15px;
    width: 80%;
  }

  .delete_integration_button {
    margin-top: 15px;
    border-radius: 5px;
    margin-left: 10px;
    min-height: 2.5em;
    min-width: 2.5em;
    padding: 0.5em;

    background-color: var(--chilean-fire-color);
    color: white;

    &:hover {
      background-color: var(--chilean-fire-color-lighter);
    }
  }

  .warning_message {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal__parentDiv {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .modal__childDiv {
    align-items: center;
    background-color: var(--background-color);
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal__buttonArea {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    order: 3;
    padding: 1em;
    width: 100%;
  }

  .modal__content {
    display: block;
    order: 1;
    padding: 1em;
    width: 100%;
  }
}
