:local {
  [data-theme="default"] {
    --th-bg-color: hsl(0, 0%, 90%);
    --th-bottom-border-color: hsl(0, 0%, 92%);
    --table-bg-color: hsl(0, 0%, 100%);
    --tr-border-color: hsl(0, 0%, 95%);
    --table-hr-color: hsl(0, 0%, 35%);
    --table-tr-expanded-bg-color: hsl(0, 0%, 97%);
    --table-empty-color: hsl(0, 0%, 90%);
    --table-empty-border-color: hsl(0, 0%, 90%);
    --table-edit-icon-fill: hsl(0, 0%, 90%);
    --table-scroll-shadow-gradient: hsla(0, 0%, 0%, 0.1);
    --table-scrollbar-track-bg: hsla(0, 0%, 0%, 0.05);
    --table-filters-bg: hsl(0, 0%, 98%);
  }

  [data-theme="dark"] {
    --th-bg-color: hsl(0, 0%, 12%);
    --th-bottom-border-color: hsl(0, 0%, 20%);
    --table-bg-color: hsl(0, 0%, 17%);
    --tr-border-color: hsl(0, 0%, 20%);
    --table-hr-color: hsl(0, 0%, 50%);
    --table-tr-expanded-bg-color: hsl(0, 0%, 12%);
    --table-empty-color: hsl(0, 0%, 20%);
    --table-empty-border-color: hsl(0, 0%, 30%);
    --table-edit-icon-fill: hsl(0, 0%, 30%);
    --table-scroll-shadow-gradient: hsla(0, 0%, 0%, 0.9);
    --table-scrollbar-track-bg: hsla(0, 0%, 0%, 0.7);
    --table-filters-bg: hsl(0, 0%, 5%);
  }

  .table {
    display: flex;
    flex-flow: column;
  }

  .table__headerCell {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .table__headerCell--clickable {
    cursor: pointer;
  }

  .tableHeaders {
    position: sticky;
    z-index: 3;
  }

  .tableHeaderRow {
    display: grid;
    width: 100%;
    min-width: fit-content;
  }

  .tableBody {
    display: grid;
    // TODO: Figure out why this had to disappear and whether or not I need it back?
    // overflow-x: scroll;
  }

  .table__sortingIcon {
    margin-left: 10px;
  }
}
