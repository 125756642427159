/** Adding main theme CSS vars */
:local {
  [data-theme="default"] {
    --kl-primary-color: #ffffff;
    --primary-text-color: var(--text-dark-color);
    --kl-placeholder-color: var(--neutral-400);
  }

  [data-theme="dark"] {
    --kl-primary-color: #000000;
    --primary-text-color: var(--text-light-color);
    --kl-placeholder-color: var(--neutral-700);
  }
}
