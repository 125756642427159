:local {
  .attempts {
    padding: 0 5px;
  }

  .attempt {
    color: var(--touch-bg);
    margin-right: 5px;
  }

  .attempted {
    color: var(--primary-blue);
    margin-right: 5px;
  }
}
