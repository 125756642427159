@import "../../style/mixins";

:local {
  .flexbox__column {
    @include flex-direction(column);

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  .flexbox__row {
    @include flex-direction(row);

    &--reverse {
      flex-direction: row-reverse;
    }
  }
}
