:local {
  .guestListItem {
    margin: 0 0 10px 5px;
  }

  .guestListItem__icons--hidden {
    border: none !important;

    > span {
      color: transparent;
    }
  }
}
