// Generated classes
// .display-1--semiBold
// .display-1--bold
// .display-1--extraBold
// .display-2--semiBold
// .display-2--bold
// .display-2--extraBold
// .heading-1--semiBold
// .heading-1--semiBoldSmall
// .heading-1--bold
// .heading-1--boldSmall
// .heading-1--extraBold
// .heading-1--extraBoldSmall
// .heading-2--semiBold
// .heading-2--semiBoldSmall
// .heading-2--bold
// .heading-2--boldSmall
// .heading-2--extr
// .heading-2--extraBoldSmall
// .heading-3--semiBold
// .heading-3--semiBoldSmall
// .heading-3--bold
// .heading-3--boldSmall
// .heading-3--extraBold
// .heading-3--extraBoldSmall
// .heading-4--semiBold
// .heading-4--semiBoldSmall
// .heading-4--bold
// .heading-4--boldSmall
// .heading-4--extraBold
// .heading-4--extraBoldSmall
// .heading-5--semiBold
// .heading-5--semiBoldSmall
// .heading-5--bold
// .heading-5--boldSmall
// .heading-5--extraBold
// .heading-5--extraBoldSmall
// .heading-6--semiBold
// .heading-6--semiBoldSmall
// .heading-6--bold
// .heading-6--boldSmall
// .heading-6--extraBold
// .heading-6--extraBoldSmall
// .paragraph-1
// .paragraph-1--semiBold
// .paragraph-2
// .paragraph-2--semiBold
// .paragraph-3
// .paragraph-3--semiBold
// .caption
// .caption--semiBold
// .footer
// .footer--semiBold

$base-font-size: 16px;
$base-font-family: "Inter";

$display-font-weights: (
  "semiBold": 600,
  "bold": 700,
  "extraBold": 800,
);
$display-font-sizes: 72px, 60px;
$display-line-heights: 80px, 72px;
$heading-font-sizes: 48px, 39px, 33px, 28px, 23px, 19px;
$heading-font-line-heights: 56px, 47px, 40px, 34px, 28px, 23px;
$heading-font-small-sizes: 34px, 33px, 28px, 23px, 19px, 14px;
$heading-font-small-line-heights: 40px, 40px, 34px, 28px, 23px, 20px;
$paragraph-font-weights: (
  "regular": 400,
  "semiBold": 600,
);
$paragraph-font-sizes: 14px, $base-font-size, 18px;
$paragraph-font-line-heights: 17px, 24px, 22px;

$font-sizes: (
  "subheading-size": 20px,
  "caption-size": 12px,
  "footer-size": 10px,
);

@mixin typography($size, $line-height, $weight, $letter-spacing) {
  font-family: $base-font-family;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@for $i from 1 through 2 {
  @each $name, $weight in $display-font-weights {
    .display-#{$i}--#{$name} {
      @include typography(
        nth($display-font-sizes, $i),
        nth($display-line-heights, $i),
        $weight,
        -0.04em
      );
    }
  }
}

@for $i from 1 through 6 {
  @each $name, $weight in $display-font-weights {
    @if $i == 1 or $i == 2 and $name != "semiBold" {
      .heading-#{$i}--#{$name} {
        @include typography(
          nth($heading-font-sizes, $i),
          nth($heading-font-line-heights, $i),
          $weight,
          -0.04em
        );
      }
    } @else {
      .heading-#{$i}--#{$name} {
        @include typography(
          nth($heading-font-sizes, $i),
          nth($heading-font-line-heights, $i),
          $weight,
          -0.02em
        );
      }
    }
    @if $i ==1 {
      .heading-#{$i}--#{$name}Small {
        @include typography(
          nth($heading-font-small-sizes, $i),
          nth($heading-font-small-line-heights, $i),
          $weight,
          -0.02em
        );
      }
    } @else {
      .heading-#{$i}--#{$name}Small {
        @include typography(
          nth($heading-font-small-sizes, $i),
          nth($heading-font-small-line-heights, $i),
          $weight,
          -0.02em
        );
      }
    }
  }
}

@for $i from 1 through 3 {
  @each $name, $weight in $paragraph-font-weights {
    @if $name == "regular" {
      .paragraph-#{$i} {
        @include typography(
          nth($paragraph-font-sizes, $i),
          nth($paragraph-font-line-heights, $i),
          $weight,
          normal
        );
      }
    } @else {
      .paragraph-#{$i}--#{$name} {
        @include typography(
          nth($paragraph-font-sizes, $i),
          nth($paragraph-font-line-heights, $i),
          $weight,
          normal
        );
      }
    }
  }
}

@each $name, $weight in $paragraph-font-weights {
  @if $name == "regular" {
    .caption {
      @include typography(12px, 18px, $weight, normal);
    }
  } @else {
    .caption--#{$name} {
      @include typography(12px, 18px, $weight, normal);
    }
  }
}

@each $name, $weight in $paragraph-font-weights {
  @if $name == "regular" {
    .footer {
      @include typography(10px, 14px, $weight, -0.02em);
    }
  } @else {
    .footer--#{$name} {
      @include typography(10px, 14px, $weight, -0.02em);
    }
  }
}
