@mixin reset {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin flex-direction($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin hover-underline {
  cursor: pointer;
  text-decoration: underline;
}
