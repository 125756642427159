:local {
  [data-theme="default"] {
    --directMessenger-background: #d5dee8;
  }

  [data-theme="dark"] {
    --directMessenger-background: linear-gradient(
      180deg,
      #192546 0%,
      rgba(23, 39, 81, 0.95) 100%
    );
  }

  .directMessenger {
    position: absolute;
    z-index: 5;
    background: var(--directMessenger-background);
    border: 0.5px solid #a1a7bc;
    border-radius: 10px 10px 0 0;
  }

  .directMessenger__list {
    overflow: scroll;
    scroll-behavior: smooth;
    color: white;
  }
}
