@import "../../../../style/mixins";
@import "../cards";

:local {
  [data-theme="default"] {
    --guest-active-card-by-color: inheirt;
  }

  [data-theme="dark"] {
    --guest-active-card-by-color: #0c0a25;
  }

  .guestActive {
    flex: 2 2 50%;
    padding: 5px;
  }

  .guestActive__card {
    background-color: var(--guest-active-card-by-color);
  }

  .guestActive__card_content {
    min-height: 140px;

    &_row {
      padding-bottom: 2em;
    }
  }

  .guestActive__text {
    padding-bottom: $text-above-value-padding;
  }

  .guestActive__value {
    color: $text-color-guest-statuses;

    &:hover {
      @include hover-underline;
    }
  }
}
