@import "../../style/helpers.scss";

:local {
  [data-theme="default"] {
    --tag-bg-color: hsl(0, 0%, 90%);
  }

  [data-theme="dark"] {
    --tag-bg-color: hsl(0, 0%, 10%);
  }

  .tagIcon__button {
    cursor: pointer;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--tag-bg-color);
    border: none;
    color: var(--primary-text-color);
    height: 32px;
    width: 32px;
    border-radius: 28px;
    padding-left: 0.125em;

    &:hover {
      background-color: var(--input-bg-color);
    }

    .tagIcon__icon {
      flex: 1;
    }
  }

  .tagIcon__button__disabled {
    cursor: not-allowed;
  }
}
