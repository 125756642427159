:local {
  [data-theme="default"] {
    --channels-container-bg: hsl(0, 0%, 95%);
    --channels-info-container-bg: hsla(0, 0%, 93%, 0.892);
    --channels-info-icon-bg: hsl(0, 0%, 50%);
    --channels-color: hsl(0, 0%, 35%);
    --channel-header-icon-fill-color: hsl(0, 0%, 55%);
    --channel-header-icon-fill-alert-color: hsl(46, 93%, 45%);
    --meeting-channel-bg: hsl(0, 0%, 95%);
    --team-profile-bg: hsl(0, 0%, 80%);
    --meeting-channel-user-bg: hsl(0, 0%, 90%);
    --meeting-channel-email-color: hsl(0, 0%, 35%);
    --meeting-channel-has-conditions-fill: rgb(5, 185, 225);
    --meeting-channel-active-btn: hsl(0, 0%, 90%);
    --team-select-bg: hsl(0, 0%, 90%);
  }

  [data-theme="dark"] {
    --channels-container-bg: hsl(0, 0%, 10%);
    --channels-info-container-bg: hsl(0, 0%, 20%);
    --channels-color: hsl(0, 0%, 35%);
    --channel-header-icon-fill-color: hsl(0, 0%, 60%);
    --channels-info-icon-bg: hsl(0, 0%, 50%);
    --channel-header-icon-fill-alert-color: hsl(46, 98%, 49%);
    --meeting-channel-bg: hsl(0, 0%, 10%);
    --team-profile-bg: hsl(0, 0%, 17%);
    --meeting-channel-user-bg: hsl(0, 0%, 7%);
    --meeting-channel-email-color: hsl(0, 0%, 90%);
    --meeting-channel-has-conditions-fill: rgb(5, 185, 225);
    --meeting-channel-active-btn: hsl(0, 0%, 22%);
    --team-select-bg: hsl(0, 0%, 7%);
  }

  .meetingTypesChannel {
    background-color: var(--meeting-channel-bg);
    border-radius: var(--border-radius);
    padding: 25px;
  }

  .meetingTypesChannelCategories {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 30%;
  }

  .meetingTypesChannelCategory {
    border-radius: 2em;
    cursor: pointer;
    padding: 0.5em 1.5em;
  }

  .meetingTypesChannelCategory--active {
    background-color: var(--meeting-channel-active-btn);
  }

  .cards {
    padding: 50px 0 25px 15px;
  }

  .content {
    margin: 0 25px;
  }

  .tabs {
    margin-top: 50px;
  }

  .icon {
    height: 100px;
    width: 100px;
  }

  .channels {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 15px;
    border-radius: var(--border-radius);
    background-color: var(--channels-container-bg);
    overflow: auto;
    max-height: 90vh;

    .channelContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .channel {
    margin: 0 0 1rem 0;
    width: 95%;

    .closeChannelCollapse {
      padding-bottom: 0;
      padding-top: 0;
      max-height: 0;
      transition: all 0.4s ease-in-out;

      > .channelInfo {
        display: none !important;
      }
    }

    .openChannelCollapse {
      max-height: 100%;
      transition: all 0.2s ease-in-out;
    }

    .cardContent {
      background-color: var(--channels-info-container-bg);

      > .channelInfo {
        display: flex;
        flex-direction: column;

        > .channelInfoItem {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          font-size: 14px;

          .channelInfoItemImg {
            margin-right: 10px;
            width: 30px;
          }

          .channelInfoItemSpan {
            text-transform: capitalize;
            margin-right: 10px;
          }

          .channelInfoItemSvg {
            margin-right: 15px;
            fill: var(--channels-info-icon-bg);
          }

          > svg {
            margin-right: 15px;
            fill: var(--channels-info-icon-bg);
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.alert {
            > svg {
              margin-right: 15px;
              fill: var(--channel-header-icon-fill-alert-color);
            }
          }
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      color: var(--channels-color);

      > svg {
        margin-right: 15px;
        fill: var(--channels-color);
      }

      > h3 {
        text-transform: capitalize;
        margin-right: 50px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .channelHeader {
    padding: 0 10px 0 10px !important; // was getting overwritten
    margin: 0;
  }

  .channelContainer {
    padding-bottom: 0;
  }

  .modalHeaderContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 80px;
  }

  .modalCollapse {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .modalCollapseButton {
    height: auto;
    padding: 0;
  }

  .channelStateText {
    margin-top: 0.5rem;
  }

  .channelEnableSwitchLabel {
    margin: 0;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    position: relative;
    height: 100%;

    .modalTitle {
      display: flex;
      align-items: center;
      justify-self: start;
      width: 100%;
      margin-left: 75px;

      &:hover {
        cursor: pointer;
      }
    }

    .modal_title__icon {
      position: absolute;
      top: 20px;
      left: 15px;
      width: 60px;
      height: 40px;
    }

    > span {
      margin-right: 50px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      color: var(--channel-header-icon-fill-color);
    }

    .header_actions {
      position: absolute;
      top: 15px;
      left: -5px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 auto 0 0;

      .header_actions__navigation_wrapper {
        list-style: none;
        position: relative;
        cursor: pointer;

        .header_actions__navigation_wrapper_toggle {
          padding: 0;
          height: 0;
        }

        .header_actions__navigation_wrapper_actions {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 500ms;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
          background-color: var(--meeting-item-bg);
          min-width: 132px;
          max-width: 132px;
          position: absolute;
          left: 5px;
          top: 10px;
          z-index: 9999;

          .header_actions__navigation_wrapper_actions_li {
            text-align: left;
            display: flex;
            border-bottom: none;
            padding: 0 15px;

            .header_actions__navigation_wrapper_actions_edit {
              margin-right: 15px;
              padding: 0;
            }

            .header_actions__navigation_wrapper_actions_clone {
              margin-right: 15px;
              padding: 0;
            }

            .header_actions__navigation_wrapper_actions_delete {
              margin-right: 15px;
              padding: 0;
            }
          }
        }

        .header_actions__navigation_wrapper_actions--is_close {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 300ms, opacity 500ms;
        }
      }
    }

    .chip {
      border: 1px solid #2878d7;
      background-color: transparent;
      border-radius: 12px;
      margin: 4px;
      line-height: 22px;
      padding: 0 10px;
      font-size: 13px;
      color: #2878d7;
      cursor: pointer;
    }
  }

  .internalError {
    margin-left: 15px;
  }

  .modal__titleAndMeetingType {
    display: flex;
    flex-direction: row;
  }

  .modal__titleAndMeetingType_title {
    font-size: 1.05em;
    padding-right: 10px;
  }

  .modal__titleAndMeetingType_dash {
    padding-right: 10px;
  }

  .modal__titleAndMeetingType_meetingType {
    place-self: center;
    font-size: 0.75em;
  }

  .linkButtonContainer {
    display: flex;
    width: 100%;
    height: 4em;
    align-items: center;
    justify-content: center;
  }

  .tagButton {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 45px;
    left: 85px;
  }

  .tagList {
    position: absolute;
    top: 45px;
    left: 85px;
  }
}
