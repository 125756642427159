@import "../../../components/theme/theme";
@import "../../theme";

:local {
  [data-theme="default"] {
    --disabled-opacity: 0.6;
    --card-custom-bg-color: hsl(0, 0%, 99%);
    --card-custom-box-shadow-color: rgba(136, 136, 136, 0.11);
    --text-color: black;
  }

  [data-theme="dark"] {
    --disabled-opacity: 0.1;
    --card-custom-bg-color: hsl(0, 0%, 10%);
    --card-custom-box-shadow-color: rgba(2, 2, 2, 0.61);
    --text-color: white;
  }

  @mixin instanceMenuTheme {
    background-color: var(--meeting-table-header-color);
    color: var(--primary-text-color);
  }

  @mixin instanceMenuItemBorder {
    border: 1px solid var(--border-color);
  }

  .instanceMenu__overflowMenuButton {
    @include instanceMenuTheme();
    border: 0;
    border-radius: 0;
    flex: 1;
  }

  .instanceMenu__overflowMenu {
    font-size: 8px;
    position: absolute;
    top: 25px;
  }

  .instanceMenu__overflowMenuItem {
    background-color: var(--multi-guest-menu-cell-background-color);
    border: 1px solid var(--multi-guest-menu-text-color);
    color: var(--multi-guest-menu-text-color);
    font-size: 12px;
    padding: 9px 40px;
    width: 100%;
  }

  .instanceMenu__overflowMenuContainer {
    background-color: var(--multi-guest-menu-cell-background-color);
    display: flex;
    position: relative;
    height: 40px;
    width: 100%;
  }

  .sendLaterModal {
    display: flex;
    justify-content: center;
  }

  .sendLater {
    background-color: var(--card-custom-bg-color);
    box-shadow: 0 5px 10px var(--card-custom-box-shadow-color);
    height: 200px;
    width: 300px;
  }

  .topBar {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
    font-size: large;
    font-weight: bold;
  }

  .closeIcon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-right: 5px;
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
    }
  }

  .pleaseChooseATime {
    padding-left: 10px;
  }

  .dateTimeArea {
    display: flex;
    justify-content: space-around;
    height: 100px;
  }

  .dateArea {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
  }

  .timeArea {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
  }

  .colon {
    margin: auto;
    font-size: xx-large;
    font-weight: bold;
    width: 20px;
    text-align: center;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(48%);
  }

  .selectedMeetings {
    display: flex;
    justify-content: center;
  }

  .belowArea {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
