$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-small: 14px;

:local {
  @mixin base_input {
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    color: var(--input-font-color);
    font-family: var(--font-family);
  }

  .input__group--left + .input {
    border-left: 0;
    padding-left: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .input__group {
    background-color: var(--input-background-color);
    border-radius: 4px;
    color: var(--input-font-color);
    font-family: var(--font-family);
    font-size: #{$font-size-large};
  }

  .input__group,
  .input__group--left {
    border: 1px solid var(--input-border-color);
    border-right: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .input__group--large {
    height: 40px;
    padding: 8px 12px;
  }

  .input--large {
    @include base_input;
    font-size: #{$font-size-large};
    height: 40px;
    padding: 8px 12px;
  }

  .input--medium {
    @include base_input;
    font-size: #{$font-size-medium};
    height: 32px;
    padding: 5px 12px;
  }

  .input--small {
    @include base_input;
    font-size: #{$font-size-small};
    height: 24px;
    padding: 1px 8px;
  }

  .input {
    font-family: var(--font-family);
    font-weight: 400;
    width: 100%;
    margin: 0;
  }

  .input:disabled {
    background-color: var(--input-disabled-background-color);
    color: var(--input-disabled-color);
  }

  .input::placeholder {
    color: var(--input-placeholder-color);
    font-weight: 100;
  }
}
