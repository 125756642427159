@import "../../../style/text";

:local {
  [data-theme="default"] {
    --message-types-user-message-background: rgba(88, 150, 199, 0.7);
    --message-types-user-message-text: #ffffff;
    --message-types-guest-message-background: rgba(193, 203, 230, 0.54);
    --message-types-guest-message-text: #606e7b;
    --message-types-info-text: #49505a;
  }

  [data-theme="dark"] {
    --message-types-user-message-background: rgba(88, 150, 199, 0.7);
    --message-types-user-message-text: #d5dbe9;
    --message-types-guest-message-background: rgba(193, 203, 230, 0.54);
    --message-types-guest-message-text: #d5dbe9;
    --message-types-info-text: #d5dbe9;
  }

  .guestMessage {
    margin-left: 50px;
    max-width: 65%;
  }

  .guestMessage__email {
    color: var(--message-types-info-text);
    margin-bottom: 5px;
  }

  @supports (backdrop-filter: blur(2px)) {
    .guestMessage__body {
      @include text-size(small);
      background: var(--message-types-guest-message-background);
      color: var(--message-types-guest-message-text);
      backdrop-filter: blur(2px);
      border: 0.3px solid #b3c9eb;
      border-radius: 2px;
      padding: 15px;
      line-height: 16px;
      word-break: break-word;
    }
  }

  @supports not (backdrop-filter: blur(2px)) {
    .guestMessage__body {
      @include text-size(small);
      background: var(--message-types-guest-message-background);
      color: var(--message-types-guest-message-text);
      border: 0.3px solid #b3c9eb;
      border-radius: 2px;
      padding: 15px;
      line-height: 16px;
      word-break: break-word;
    }
  }

  .guestMessage__icon_row {
    margin: 0 0 10px 10px;
    color: var(--message-types-info-text);
  }

  .guestMessage__status {
    color: var(--message-types-info-text);
  }

  .userMessage {
    margin-right: 50px;
    max-width: 65%;
  }

  .userMessage__email {
    color: var(--message-types-info-text);
    margin-bottom: 5px;
  }

  @supports (backdrop-filter: blur(2px)) {
    .userMessage__body {
      display: block;
      @include text-size(small);
      background: var(--message-types-user-message-background);
      color: var(--message-types-user-message-text);
      backdrop-filter: blur(2px);
      border: 0.3px solid #b3c9eb;
      border-radius: 2px;
      padding: 15px;
      line-height: 16px;
      word-break: break-word;
      min-width: fit-content;

      img {
        max-height: 300px;
        max-width: 300px;
      }
    }
  }

  @supports not (backdrop-filter: blur(2px)) {
    .userMessage__body {
      display: block;
      @include text-size(small);
      background: var(--message-types-user-message-background);
      color: var(--message-types-user-message-text);
      border: 0.3px solid #b3c9eb;
      border-radius: 2px;
      padding: 15px;
      line-height: 16px;
      word-break: break-word;
      min-width: fit-content;

      img {
        max-height: 300px;
        max-width: 300px;
      }
    }
  }

  .userMessage__icon_row {
    margin: 0 0 10px 10px;
  }

  .userMessage__status {
    color: var(--message-types-info-text);
  }

  .userMessage_icon_row__icon {
    margin-left: 10px;
    color: var(--message-types-info-text);
  }

  .systemNote {
    margin-bottom: 5px;
  }

  .systemNote__text {
    color: var(--message-types-info-text);
    font-weight: 600;
    font-style: anywhere;
    line-height: 160%;
  }

  .emailStatus {
    margin-top: 5px;
  }

  .status_row {
    display: flex;
  }

  .beta {
    margin-left: 5px;
    margin-top: auto;
    font-size: x-small;
  }
}
