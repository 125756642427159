:local {
  [data-theme="default"] {
    --editor-text-color: hsl(0, 0%, 30%);
    --editor-bg-color: hsl(0, 0%, 99%);
    --editor-border-color: hsl(0, 0%, 90%);
  }

  [data-theme="dark"] {
    --editor-text-color: hsl(0, 0%, 90%);
    --editor-bg-color: hsl(0, 0%, 20%);
    --editor-border-color: hsl(0, 0%, 10%);
  }

  .quill_wrapper {
    :global {
      .ql-stroke {
        stroke: var(--editor-text-color) !important;
      }

      .ql-editor {
        font-size: var(--font-size-large);
        font-style: var(--font-family);
        margin-top: 8px;
        padding-top: 0;
        padding-left: 2px;
        padding-right: 2px;
        height: 19rem;
        overflow: auto;
        line-break: normal;
        overflow-wrap: normal;
        list-style: inside;
      }

      .ql-container {
        border: none !important;
      }

      .ql-mergeFields {
        width: 125px;
      }

      .ql-picker-label {
        border-radius: 5px;
      }

      .ql-picker-options {
        margin-top: 2px !important;
        border-radius: 5px;
        overflow: scroll;
        min-height: fit-content;
        max-height: 300px;
      }

      .ql-toolbar {
        border: none !important;
        background-color: var(--meetingTypes-primary-background-color);
        border-bottom: 1px solid #63707c !important;
      }

      .ql-tooltip {
        left: 0 !important;
        top: 0 !important;
      }
    }
  }
}
