[data-theme="default"] {
  --empty-icon-color: hsl(0, 0%, 80%);
}

[data-theme="dark"] {
  // TODO(Matt): Remove the white, make a variable
  --empty-icon-color: white;
}

.emptyView__container {
  padding: 25px 0 50px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: var(--bg);
}

.emptyView__icon {
  color: var(--empty-icon-color);
}

.emptyView__name {
  color: var(--primary-color);
  margin-right: 10px;
}
