:local {
  .logo {
    width: 200px;
    height: 200px;
  }

  .reauth_container {
    width: 90%;
    display: flex;
  }

  .betaChip {
    right: 10px;
    position: absolute;
  }
}
