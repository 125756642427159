:local {
  .tagList__row {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .tagList__cellButton {
    border: none;
    background: none;
    color: var(--primary-color);
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .tagListItem {
    align-items: center;
    background-color: var(--tag-bg-color);
    border: none;
    color: var(--primary-text-color);
    cursor: pointer;
    display: flex;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    height: var(--meeting-tag-height);
    line-height: var(--meeting-tag__name-text__line-height);
    flex: 0;
    max-width: 38ch;
    white-space: nowrap;
    padding: 0.25em 0.7ch;
    margin: var(--meeting-tag-vmargin) 0.3ch;
  }

  .tagListItem__disabled {
    cursor: not-allowed;
  }

  .tagListItem__nameText {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17em;
  }

  .tagList {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .tagList__remainingCount {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
