@import "./theme";

:local {
  [data-theme="default"] {
    --dd-bg-img: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23555555%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23757575%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }

  [data-theme="dark"] {
    --dd-bg-img: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23bababa%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23bababa%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }

  .dropdown {
    background-color: var(--dropdown-background-color);
    padding: 5px 35px 5px 25px;
    text-transform: capitalize;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: var(--dd-bg-img) !important;
    background-size: 0.8em !important;
    background-position: calc(100% - 1.3em) center !important;
    background-repeat: no-repeat !important;
    color: var(--primary-text-color);
    cursor: pointer;
    font-family: var(--font-family);

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .locked_dropdown__input {
    margin-right: 30px;
    width: 100%;
  }

  .locked_dropdown {
    position: relative;
    width: 100%;
  }

  .locked_dropdown__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-45%);
  }
}
