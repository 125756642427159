:local {
  .attendees__instanceStage--initialized {
    background-color: #99bbe3;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--initializing {
    background-color: #c1d6ef;
    color: #2e2f53;
  }

  .attendees__instanceStage--init_fault {
    background-color: #9da8ee;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--init_ready_to_activate {
    background-color: #99bce3;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--scheduling {
    background-color: #72a2d8;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--queued {
    background-color: #cea8bc;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--scheduled {
    background-color: #a3a2d3;
    border: 0 solid #a3a2d3;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--completed {
    background-color: #3e8171;
    color: var(--text-light-color);
  }

  .attendees__instanceStage--no_quorum {
    background-color: #5f6672;
    color: var(--text-dark-color);
  }

  .attendees__instanceStage--cancelled {
    background-color: #8d94a0;
    color: var(--text-dark-color);
  }

  .attendees__showDetails {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--attendees-guests-background-color);
    padding-bottom: 0.5em;
    border-bottom: var(--attendees-guest-border);
  }
}
