:local {
  [data-theme="default"] {
    --card-bg-color: hsl(0, 0%, 99%);
    --card-bg-color-darker: hsl(0, 0%, 97%);
    --card-text-color: hsl(0, 0%, 19%);
    --card-icon-color: hsl(0, 0%, 50%);
    --card-box-shadow-color: rgba(136, 136, 136, 0.11);
    --card-box-shadow-active-from: hsla(202, 94%, 17%, 0.331);
    --card-box-shadow-active-to: hsla(202, 94%, 17%, 0.001);
    --card-error: hsla(357, 93%, 46%, 0.5);
    --card-valid: hsla(83, 93%, 46%, 0.5);
  }

  [data-theme="dark"] {
    --card-text-color: hsl(0, 0%, 90%);
    --card-bg-color: hsl(0, 0%, 10%);
    --card-bg-color-darker: hsl(0, 0%, 7%);
    --card-icon-color: hsl(0, 0%, 55%);
    --card-box-shadow-color: rgba(2, 2, 2, 0.61);
    --card-box-shadow-active-from: hsl(0, 0%, 0%);
    --card-box-shadow-active-to: hsl(0, 0%, 0%);
    --card-error: hsla(357, 93%, 46%, 0.5);
    --card-valid: hsla(83, 93%, 46%, 0.5);
  }

  .card {
    position: relative;
    background: var(--card-bg-color);
    border-radius: var(--border-radius);
    box-shadow: var(--kl-shadow--xs);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

    &.dark {
      background: var(--card-bg-color-darker);
    }

    &.active {
      transform: scale(1.03);
    }

    &.error {
      border: 1px solid var(--card-error);
    }

    &.valid {
      border: 1px solid var(--card-valid);
    }

    > .content {
      padding: 10px 25px;
      color: var(--card-text-color);
      width: 100%;
    }

    .header {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      font-weight: 600;
      font-size: 16px;
      color: var(--card-title-color);
      padding: 15px 20px;
      display: flex;

      .icon {
        margin-right: 15px;

        svg {
          fill: var(--card-icon-color);
        }
      }
    }
  }
}
