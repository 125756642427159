.activeSwitch__container--readyToActivate {
  animation: pulsate 1.75s linear infinite;
  box-shadow: 0 0 10px rgba(62, 132, 232, 0.8);
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 0.3);
  }

  35% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 0.4);
  }

  40% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 0.8);
  }

  50% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 1);
  }

  75% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 0.7);
  }

  100% {
    box-shadow: 0 0 10px rgba(62, 132, 232, 0.3);
  }
}
