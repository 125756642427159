:local {
  [data-theme="default"] {
    --background-color: #fafafa;
    --border-color: #dcdcdc;
    --meeting-table-header-color: #e6e6e6;
    --primary-text-color: var(--text-dark-color);
    --kl-placeholder-color: var(--neutral-400);
  }

  [data-theme="dark"] {
    --background-color: #323232;
    --border-color: #494949;
    --meeting-table-header-color: var(--background-color);
    --primary-text-color: var(--text-light-color);
    --kl-placeholder-color: var(--neutral-700);
  }
}
