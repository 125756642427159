.modal--content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  z-index: 10001;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-y: auto;
}

.modal--content-right {
  top: 50%;
  left: 50%;
  right: 0;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  z-index: 9950;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-y: auto;
}

.modal__overlay--transparent {
  background-color: rgba(#ffffff, 0);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal--overlay {
  background-color: rgba(#333333, 0.6);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.kronologic--blurred {
  filter: blur(5px);
}
